var site = site || {};

/**
 * This ADA class is used for Main Menu(gnav) Navigation, It is also used to access Popup windows
 * and also sets focus to Active Item using custom outline.
 * @param {e} keyCode value is passed to ADA class to define the corresponding action to proceed
 * such as Menu Navigation or Popup Window open.
 *
 * LIST OF METHODS AVAILABLE:
 * enterKey - Method used to bind enterKey event.
 * getKeycode - Method used to get keyCode value of keyboard event triggered.
 * site.restrict_navigation - Method used to retain Tab Focus within selected container.
 * tabsNavigation - Method used to access products placed within Tab controls
 * productsNavigation - Method used to access each individual product placed within Tab controls
 * and enables to view product using QuickView mode
 * customFocusNavigation - Method used to set focus on active element with custom outline when
 * tab key is pressed
 */
(function($) {
  Drupal.behaviors.ada = {
    attach: function(context) {
      var $prevActiveElem = null;

      /**
       * This method is used to bind enter key event for elements
       * @param {callback function} Callback function that will execute after
       * enter key is pressed
       */
      $.fn.enterKey = function(fnc) {
        return this.each(function() {
          $(this).on('keydown', function(e) {
            var keyCode = site.getKeycode(e);
            if (keyCode === 13) {
              fnc.call(this, e);
            }
          });
        })
      };

      /**
       * This method is used to get key code of the event passed
       * @param {event} Current event whose key code needs to be retrieved
       */
      site.getKeycode = function(e) {
        var keyCode = (e.keyCode ? e.keyCode : e.which);
        return keyCode;
      };

      /**
       * This method is used to retain focus within container/popup window
       * which is currently active when navigation is made through Tab key
       * @param {element} Current element/container within which focus should be maintained
       */
      site.restrict_navigation = function(element) {
        var tabbables = element.find(":tabbable");
        var first = tabbables.filter(":first");
        var last = tabbables.filter(":last");

        element.on('keydown', function(e) {
          if (e.keyCode !== 9 || e.isDefaultPrevented()) {
            return;
          }
          //When focus reached last or target element move focus to first element of the container opened
          if ((e.target === last[0] || e.target === element[0]) && !e.shiftKey) {
            first.focus();
            e.preventDefault();
          //When focus reached first or target element move focus to last element of the container opened
          } else if ((e.target === first[0] || e.target === element[0]) && e.shiftKey) {
            last.focus();
            e.preventDefault();
          }
        });
      };

      //This method is used to access products placed within Tab control
      var tabsNavigation = function() {
        $('[role="tab"]').on('keydown', function(e) {
          var $this = $(this);
          var $prev = $this.prev();
          var $next = $this.next();
          var $target = new Array();

          switch (e.keyCode) {
            case 37: //  left arrow
              $target = $prev;
              break;
            case 39: //  right arrow
              $target = $next;
              break;
            case 9:
              $prev.attr('tabindex', '-1');
              $this.nextAll().attr('tabindex', '-1');
              break;
            case 13: //  enter
             if ($this.children('a').length === 1) {
                $this.children('a')[0].click();
              } else {
                $this.trigger('click');
              }
              break;
          }

          if ($target.length > 0) {
            $this.attr({
              'tabindex': '-1',
              'aria-selected': null
            });
            $target.attr({
              'tabindex': '0',
              'aria-selected': true
            }).focus();
          }
        });
      };

      // for primary nav
      var primaryMenuNavigation = function() {
        var $menuItems = $('.js-nav-category-trigger', '.js-site-header--v1').not('.js-subnav .menu__subnav .js-nav-category-trigger');
        $menuItems.each(function() {
          $(this).attr({
            'tabindex': 0,
            'aria-expanded': 'false',
            'aria-haspopup': 'true',
            'role': 'menu',
            'aria-label': $(this).text()
          });
        });
      };

      /**
       * This method is used to access each individual product placed
       * within Tab controls and able to view product using QuickView mode
       */
      var productsNavigation = function(e) {
        var $parent_grid = $('.js-product-grid');
        var targetElem = '.js-access-content, .js-access-button, .js-spp-link';
        var $product_grid_item = $('.js-product-grid-item');
        $parent_grid.on('keydown', targetElem, function(e) {
          var $this = $(this);
          var keyCode = site.getKeycode(e);
          switch (keyCode) {
            case 9:
              if (e.shiftKey && $(this).hasClass('js-product-grid-item')) {
                 $(this).prevAll('.focused').removeClass('focused');
              }
              break;
            case 13: //  enter
            case 32: //  space
              if ($this.hasClass('js-quickshop-launch-inline')) {
                $this.addClass('js-active-qs');
              };
              $(document).on("product.quickshop.launch.inline", ".js-product", function(e, data) {
                var $quickshopContainer = $('.js-quickshop-container');
                var $productContExpanded = $('.js-access-content-expanded');
                $($productContExpanded, $quickshopContainer).focus();
                site.restrict_navigation($quickshopContainer);
                $this.attr('aria-expanded', 'true');
              });
              $(document).on("product.quickshop.close.inline", ".js-product", function(e, data) {
                $('.js-active-qs').focus();
                $this.attr('aria-expanded', 'false');
              });
              $this.trigger('click');
              return false;
              break;
            case 37: //  left arrow
              if ($this.hasClass('js-product-grid-item')) {
                $this.find('.js-product').removeClass('focused');
                $this.prevAll('.js-access-content').first().focus();
              }
              break;
            case 39: //  right arrow
              if ($this.hasClass('js-product-grid-item')) {
                $this.find('.js-product').removeClass('focused');
                $this.nextAll('.js-access-content').first().focus();
              }
              break;
          }
        });
      };

      /**
       * This method is used to add custom class for each active element
       * in HTML Document while using TAB key, to make element focus
       * with keyboard navigation
       */
      var customFocusNavigation = function(e) {
        $(window).keyup(function(e) {
          var keyCode = site.getKeycode(e);
          if ((keyCode === 9 || keyCode === 13) && $(document.activeElement).length) {
            // Remove custom outline class from previously targeted element
            if ($prevActiveElem) {
              $prevActiveElem.removeClass('custom-outline');
            }
            // Add custom outline class to current Active element
            $prevActiveElem = $(document.activeElement);
            $prevActiveElem.addClass('custom-outline');
          }
        });

        //Trigger click event to the custom class added.
        $('.js-access-link').enterKey(function() {
          $(this).trigger('click');
        });
      };

      /**
       * Code for video tout's
       */
      $('.basic-tout__image').on('keydown', function(e) {
        var keyCode = site.getKeycode(e);
        if (keyCode === 13) {
          $(this).find('.basic-tout-overlay__image-trigger').trigger('click');
        }
      });

      //Removing non focusable element
      if ($('.js-spp-content-focus').length > 0) {
        $('.js-basic-grid-item-inner').removeAttr('tabindex');
      }

      //Add to favorites icon
      $('.js-add-to-favorites').on('keydown', function(e) {
        var keyCode = site.getKeycode(e);
        if (keyCode === 13) {
          $(this).trigger('click');
        }
      });

      //Add focusin product quickshop
      var $productFocus = $('.js-cart-block-container, .js-access-button, .js-add-to-cart');
      $productFocus.on('focus', function() {
        $(this).addClass('custom-outline');
      }).focusout(function() {
        $(this).removeClass('custom-outline');
      });

      //Removing non focus element
      if ($('.js-mpp-container--custom').length > 0) {
        $('.js-product-grid-tout').parent().removeAttr('tabindex');
      }

      //Adding focus for checkbox selection
      $("input:checkbox").focusin(function(e) {
        $(this).parent().addClass('custom-outline');
      }).focusout(function(e) {
        $(this).parent().removeClass('custom-outline');
      });

      tabsNavigation();
      productsNavigation();
      customFocusNavigation();
      primaryMenuNavigation();
    }
  };
})(jQuery);

/**
 * This method is used to return focusable element from the Popup Window selected
 */
$.curCSS = function(element, prop, val) {
  return $(element).css(prop, val);
};


(function ($, site, generic) {
  /**
   * Adjustable Time behavior.
   */
  Drupal.behaviors.adjustableTime = {
    attach: function(context) {
      if (typeof site.at !== 'undefined') {
         return;
      }
      window.popupSignOutTimer = null;
      window.sessionIdlePopupTimer = null;
      window.sessionTimeoutCountDownTimer = null;
      site.at = true;
      // When session is idle for below mentioned minutes, user is logged out. Value should be in minutes.
      var signOutDuration = Drupal.settings.signout_duration ? Drupal.settings.signout_duration : 15;

      // Session time-out alert is displayed before the below mentioned seconds from the actual time-out. Value should be in seconds.
      var sessionTimeoutRemainingTimeInSec = 20;

      // Convert seconds to minutes
      var sessionTimeoutRemainingTimeInMin = sessionTimeoutRemainingTimeInSec / 60;

      // CAM-927 - removing session timeout behavior for US
      var disabledLocale = checkIfLocaleDisabled(generic.cookie('LOCALE'));

      function checkIfLocaleDisabled(locale) {
        var isDisabled = ['en_US', 'add locales as needed'];
        return ~isDisabled.indexOf(locale);
      }

      function getCookieValue(a, b) {
        b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
      }

      // Log out user after 15 minutes on secure(https://) pages
      if ((document.location.protocol === 'https:') && (!getCookieValue('debug_noTimeout') && !disabledLocale)) {
        clearSignOutTimer = function() {
          if (window.popupSignOutTimer !== null) {
            window.clearTimeout(window.popupSignOutTimer);
          }
        };

        setSignOutTimer = function() {
          window.popupSignOutTimer = window.setTimeout(function() {
            generic.overlay.hide();
            clearSessionCountDownTimer();
            signOutNow();
          }, signOutDuration * 60 * 1000);
          if (generic.cookie('LOCALE') === 'en_US') {
            sessionIdlePopup();
          }
        };

        sessionIdlePopup = function() {
          if (window.sessionIdlePopupTimer !== null) window.clearTimeout(window.sessionIdlePopupTimer);
          window.sessionIdlePopupTimer = window.setTimeout(function() {
            var $sessionIdlePopUp = $('#sessionIdlePopUp');
            var $sessionIdlePopUpContent;

            // User needs to be reminded of session time-out only if he is logged-in
            if (site.userInfoCookie.getValue('signed_in') - 0 === 0) return false;

            if ($sessionIdlePopUp.length === 0) {
              $sessionIdlePopUpContent = site.template.get({ name: 'adjustable_time' });
              $sessionIdlePopUp = $('<div/>').append($sessionIdlePopUpContent).addClass('js-session-idle-popup-tab-order');
              $sessionIdlePopUp.attr('id', 'sessionIdlePopUp');
              $sessionIdlePopUp.hide();

              $('body').append($sessionIdlePopUp);
            }

            // Show idle session alert pop-up
            generic.overlay.launch({
              includeBackground: true,
              overlayClose: false,
              width: "680px",
              height: "250px",
              closeButton: true,
              inline: true,
              href: '#sessionIdlePopUp',
              onComplete: function () {
                $sessionIdlePopUp.show();
              },
              onClosed: function () {
                $sessionIdlePopUp.hide();
              }
            });

            $('#cboxOverlay').addClass('pre_chat_overlay_bg');

            $('#colorbox').attr({'role': 'presentation', 'tabindex': '0'}).focus();


            $.fn.escape = function(callback) {
              return this.each(function() {
                $(document).on("keydown", this, function(e) {
                  var keycode = ((typeof e.keyCode != 'undefined' && e.keyCode) ? e.keyCode : e.which);
                  if (keycode === 32) {
                    callback.call(this, e);
                  };
                });
              });
            };

            $('.js-session-idle-popup-tab-order').escape(function() {
              $('.js-session-idle-popup-logout').trigger('click');
            });

            // Lets show countdown for the user to choose his options in sign-out alert pop-up
            clearSessionCountDownTimer();

            var sessionTimeoutCountDownCntr = 0,
              $sessionTimeoutCounter = $('.js-session-timeout-count-down', $('#colorbox'));

            $sessionTimeoutCounter.text(sessionTimeoutRemainingTimeInSec);
            window.sessionTimeoutCountDownTimer = window.setInterval(function() {
              sessionTimeoutCountDownCntr++;
              var sessionTimeoutString = sessionTimeoutRemainingTimeInSec - sessionTimeoutCountDownCntr;
              if (sessionTimeoutString > -1) {
                $sessionTimeoutCounter.text(sessionTimeoutString);
              }
            }, 1000);
          }, (signOutDuration - sessionTimeoutRemainingTimeInMin) * 60 * 1000);
        };

        var clearSessionCountDownTimer = function() {
          if (window.sessionTimeoutCountDownTimer !== null) window.clearInterval(window.sessionTimeoutCountDownTimer);
        };

        // Sign-out destination URL
        signOutNow = function() {
          if (site.userInfoCookie.getValue('signed_in') - 0 === 0) return false;
          document.location.href = '/account/signin.tmpl?_SUBMIT=signout';
        };

        sessionExtend = function() {
          generic.overlay.hide();
          clearSessionCountDownTimer();
          clearSignOutTimer();
          setSignOutTimer();

          // RPC Sent to Retain Session
          generic.jsonrpc.fetch({
            method: 'session.get',
            onSuccess: function() {
              // We have extended the session
            }
          });
        };

       // On page load clear any time-outs and start ticking a new one
        clearSignOutTimer();
        setSignOutTimer();
        // Append the session time-out alert pop-up box
        $(document).ready(function() {
          var $body = $('body');

          // "Logout" CTA from session time-out alert pop-up
          $body.on('click', '.js-session-idle-popup-logout', function() {
            signOutNow();
          });
          // "Stay connected" CTA from session time-out alert pop-up
          $body.on('click', '.js-session-idle-popup-retain-session', function() {
            sessionExtend();
          });
        });
      }
    }
  };
})(jQuery, window.site || {}, window.generic || {});

/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _links.scss under .clickable-processed
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */

(function($) {
  Drupal.behaviors.clickable = {
    attach: function(context) {
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function(e) {
        // To disable background click when any share icons are clicked
        // When the full module is click enabled
        if ($(e.target).closest('.js-la-share--v1').length > 0) {
          return;
        }
        window.location.href = $(this).data('clickable');
      });
    }
  };
})(jQuery);

/**
 * Generic expander functionality
 * case for basic expanding menu/ul
 *
 * Expanding Menu Usage / example markup:
 *
 *  <ul class="collapsible-menu">
 *    <li class="collapsible-menu__parent">
 *      <h3 class="collapsible-menu-trigger js-collapsible-menu-trigger">Parent Header</h3>
 *      <div class="collapsible-sub-menu">
 *        <ul class="">
 *          <li class=""><a class="" href="#">Link 1</a></li>
 *          <li class=""><a class="" href="#">Link 2</a></li>
 *          <li class=""><a class="" href="#">Link 3</a></li>
 *        </ul>
 *      </div>
 *    </li>
 *  </ul>
 *
 * static link variant inside menu
 *
 *  <ul class="collapsible-menu cs-menu"><li>
 *  		<h3 class="collapsible-menu__static cs-menu__static"><a href="/customer-service-contact-us">Contact Us</a></h3>
 *  	</li>
 *  </ul>
 *
 * Styles applied:
 * .collapsible-menu__parent - plus when closed, minus when open
 * .collapsible-sub-menu - closed by default
 * additional BEM classes can be added for styling
 *
 *
 * Expanding Mobile Block Usage / example markup:
 *  <section class="content-container collapsible-block">
 *    <a class="collapsible-block__anchor" name="cs-orders-section1" id="cs-orders-section4"></a>
 *
 *    <header class="collapsible-block__header clearfix">
 *      <h4 class="collapsible-block__title collapsible-block__title--pc mobile-hidden">Order Cancellations</h4>
 *      <div class="collapsible-block__title collapsible-block__title--mobile pc-hidden js-collapsible-block-trigger">
 *        <span>Order Cancellations</span>
 *        <i class="collapsible-block__icon"></i>
 *      </div>
 *    </header>
 *
 *    <div class="collapsible-block__content">
 *      <div class="clearfix"></div>
 *      <div class="collapsible-block__content-tout clearfix">
 *        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non graveda quam. Ut sed ultricies neque. Quisque pulvinar turpis vel magna iaculis vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec posuere interdum purus, quis porttitor dolor porta ac.
 *      </div>
 *
 *    </div>
 *  </section>
 */
 var site = site || {};

 (function($){

  /**
   * Basic collapsible-menu behavior. Used in custom markup.
   */
  Drupal.behaviors.collapsibleMenu = {
    attach: function(context) {
      $('.js-collapsible-menu-trigger', context).on('click', function() {
        $(this).closest('.collapsible-menu__parent').toggleClass('collapsible-menu__parent--expanded');
        $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
      });
    }
  };

  /**
   * Basic collapsible-block behavior.
   */
  Drupal.behaviors.collapsibleBlock = {
    init: function(context) {

      context = context.currentTarget || context;

      var $block = $('.collapsible-block', context);
      var $trigger = $block.find('.js-collapsible-block-trigger');

      $block.each(function() {
        // default expansion
        $trigger.unbind('click').on('click', function() {
          $(this).closest('.collapsible-block').toggleClass('collapsible-block--alt');

          $(document).trigger('collapsibleBlock.trigger', {
            blocks : $block,
            trigger : $(this)
          });
        });

      });

    },
    attach: function(context) {
      $(document).on('collapsibleBlock.refresh', $.proxy(this.init, this));

      this.init(context);
    }
  };

})(jQuery);

(function($) {

$(document).on('loader.start', function(e, $element, params) {
  params = typeof params !== 'undefined' ? params : {};

  // Helper function(s)
  var _ia = function(n, h) {
    var k = '';
    for (k in h) {
      if (h[k] === n) {
        return true;
      }
    }
    return false;
  };

  // Default parameters
  var styles = ['simple'];
  if (!params.style || (!!params.style && !_ia(params.style, styles))) {
    params.style = 'simple';
  }

  // Make sure we don't already have a loading animation
  var $old_e = $('.js-loader--animation', $element);
  if (!!$old_e.length) {
    return;
  }

  // If there's no element to bind to, then get outta here
  if (!$element.length) {
    return;
  }

  // Get element specs
  var w  = parseInt($element.outerWidth(true)),
      wh = $(window).height(),
      o  = $element.offset(),
      h  = parseInt($element.outerHeight(true)),
      p  = $element.css('position'),
      s  = 'small';

  // Figure out which loader to use
  if (w >= 150 && h >= 150) {
    s = 'large';
  }
  if (w >= 250 && h >= 250) {
    s = 'large';
  }
  params.size = s;

  // Create & position the loader element
  var $loader = $('<div />').addClass('loader js-loader--animation loader--' + params.size + ' loader--' + params.style);
  var css = {
    'opacity': '0.7'
  };

  var projected_position = parseInt(o.top) + parseInt(h / 2);
  if (projected_position > wh) {
    $loader.css('top', parseInt( (wh - parseInt(o.top)) / 2 ));
  }

  if (p === 'static') {
    $element.data('old-position', p).css('position', 'relative');
  }

  // And drop the element on the page
  $element
    .css(css)
    .prepend($loader);
});

$(document).on('loader.end', function(e, $element) {
  if (!$element.length) {
    console.log('loader.end: element does not exist.');
    return;
  }

  // Remove the loading animation element
  $element.css('opacity', '');
  $('.js-loader--animation', $element).remove();

  // If there's an old position property, remove both attributes to make to fall back
  // Note: this should be done after the loading animation is removed to avoid it jumping around
  if ($element.data('old-position') !== '') {
    $element.removeAttr('data-old-position').css('position', '');
  }
});

})(jQuery);


(function($){
  window.site = site || {};
  site.loyalty = {
    /**
     * Accepts a jQuery object of elements and converts prices to points
     */
    calculatePoints: function($el) {
      $el.each(function() {
        // We are inserting the price x multipler as the Pure Privilege points
        var $pointsDisplay = $(this);
        var pointsRounded = '';
        if(!$pointsDisplay.data('price')) {
          return;
        }
        // Replaced comma from data-price value with '.' in order to calculate the pp points in CA FR
        // http://stackoverflow.com/questions/4572194/regex-to-replace-everything-except-numbers-and-a-decimal-point
        var price = $pointsDisplay.data('price').replace(/[,]/g, '.').replace(/[^0-9.]/g, '');
        var multiplier = site.translations.product.product_pp_multiplier;
        var pointsText = site.translations.product.product_pp_text;

        pointsRounded = Math.round(multiplier * price);
        $pointsDisplay.html(pointsRounded + ' ' + pointsText);
      });
    }
  };
})(jQuery);

var site = site || {};
site.template = site.template || {};

(function($) {

Drupal.behaviors.offers = {
  linkedBasedOffer: function() {
    if ($.cookie('offer_info') == null || $.cookie('offer_info').length == 0) {
      return;
    }
    var offer_code = $.cookie('offer_info').split(":")[1];
    site.onLoadRpc.requests.push({
      method: 'offer.linkedOfferState',
      params: [offer_code],
      onSuccess: function(data) {
        var message = data.result.value.linked_offer.offer_message;
        var template = offer_code;
        var rendered = site.template.get({
          name: template,
          data: {message : message}
        });
        generic.overlay.launch({
          content: rendered
        });
      },
      onError: function(data) {}
    });
  },

  attach: function(context, settings) {
    if (this.attached) {
      return;
    }
    this.attached = true;

    this.linkedBasedOffer();
  },

  attached: false
};

})(jQuery);

(function($, site, generic) {

site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

site.onLoadRpc.init = function() {
  // User stuff
  var signedIn = Number(site.userInfoCookie.getValue('signed_in')) === 1 || Number(site.userInfoCookie.getValue('csr_logged_in')) === 1;

  if (signedIn) {
    site.onLoadRpc.requests.push({
      method:   'user.fullData',
      params:   [{}],
      onSuccess : function(data) {
        // This is for the Phone Order app to recognize values on Drupal pages
        if (!data || !data.result) {
          return;
        }

        var val = data.result.value;

        if ((val.csr_email !== undefined) && (val.csr_email !== null)){
          $('#csr_header_holder').removeClass('hidden');
        }

        // Set a body class attribute based on user logged in status
        $('body').addClass( (val.signed_in === 1) ? 'elc-user-state-logged-in' : 'elc-user-state-anonymous' );

        // Populate user data
        $('[data-pg-object="user"] > [data-pg-prop], [data-pg-object="user"][data-pg-prop]').each(function() {
          var $me = $(this);
          $me.html(val[$me.attr('data-pg-prop').toLowerCase()]);
        });

        // generic.user.setUser(val);

        $(document).trigger('user.loaded', val);
      },
      onFailure : function() {}
    });
  }
  else {
    // generic.user.setUser({});
    $('body').addClass('elc-user-state-anonymous');
    $(document).trigger('user.loaded', {});
  }
};

/*
 * site.onLoadRpc.requests - a global array of RPC request objects
 * must be initialized pre-DOM-load and formatted like this:
 * [
 *     {
 *         "method":   "user.json",
 *         "params":   [{}],
 *         "getParams" : function () { return [{}] },  * one of 'params' or 'getParams' is required
 *         "onSuccess" : function () { },
 *         "onFailure" : function () { }
 *     }
 * ]
 */
site.onLoadRpc.fetch = function() {
  var requests = site.onLoadRpc.requests || [];
  var rLen     = requests.length;
  var queryVals = [];

  for (var i = 0, len = rLen; i < len; i++) {
    var postMethod = requests[i].method || 'rpc.form';

    if (typeof requests[i].getParams === 'function') {
      requests[i].params = requests[i].getParams();
    }

    queryVals[i] = {
      method: postMethod,
      params: requests[i].params,
      id: i + 1
    };
  }

  if (!queryVals.length) {
    return;
  }

  var successHandler = function(data) {
    for (var i = 0, len = rLen; i < len; i++) {
      var fn = requests[i].onSuccess;
      if (typeof fn !== 'function') {
        continue;
      }
      fn( data[i] );
    }
  };

  var url = generic.jsonrpc.url || '/rpc/jsonrpc.tmpl';
  var options = {};

  // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses
  // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
  // This snippets searches for such entries and adds 'em to the request url.
  var url_params = '';
  $(queryVals).each(function() {
    if (this.params[0].url_params) {
      if (this.params[0].url_params.charAt(0) === '&') {
        url_params += this.params[0].url_params;
      }
      else {
        url_params += '&' + this.params[0].url_params;
      }
    }
  });
  if (url_params !== '') {
    url += '?' + url_params.substring(1);
  }

  options.data = $.param({JSONRPC: JSON.stringify(queryVals)});

  options.type = 'POST';
  options.success = function(data, textStatus, response) {
    successHandler(data, textStatus, response);
  };
  options.error = function(jqXHR, textStatus, errorThrown) { console.log(jqXHR, textStatus, errorThrown); };
  generic.jsonrpc.fetch({url: url, options: options});
};

$(function() {
  $(document).trigger('onLoadRpc.fetch');
  // Set user cookie
  site.userInfoCookie.init();
  site.onLoadRpc.init();
  site.onLoadRpc.fetch();

    if (window.location.hash === '#sign-in') {
      setTimeout(function () {
        $('#return-user-link').trigger('click');
      }, 500);
    }
    if (window.location.hash === '#new-account') {
      setTimeout(function () {
        $('#new-account-link').trigger('click');
      }, 500);
    }
});

})(jQuery, window.site || {}, window.generic || {});

var generic = generic || {};

(function($) {
  'use strict';

  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch : function(args, event) {
      if (typeof event !== 'undefined' ) {
        event.preventDefault();
      }

      var $activeElement = $(document.activeElement);
      // ColorBox args sent along
      var cboxArgs = {
        'height': 'auto',
        'width': '768px',
        'margin': 'auto',
        'opacity': 1,
        'transition': 'none',
        'onComplete': function() {
          $(document).trigger('colorbox.complete');
        },
        onClosed: function() {
          $activeElement.focus();
        },
      };

      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides

      // trigger 'colorbox.complete' event even if args.onComplete exists
      if (typeof args.onComplete === 'function') {
        cboxArgs.onComplete = function() {
          $(document).trigger('colorbox.complete');
          args.onComplete.call(this);
        };
      }

      // When mobile, override any height/width and set to 100%
      if ($(window).width() <= 768) {
        var afterPayOverlay = args.cssClass && args.cssClass.indexOf('afterpay-overlay-container') !== -1;
        _.extend(cboxArgs, {
          'height': afterPayOverlay ? '100%' : 'auto',
          'width': '100%',
          'transition': 'none'
        });
      }

      // Actual content of the overlay
      if (typeof args.content !== 'undefined') {
        cboxArgs.html = args.content;
      }

      // A custom class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') {
        cboxArgs.className = args.cssClass;
      }

      $(document).off('colorbox.complete').on('colorbox.complete', function() {
        // Scroll to an anchor, if sent over
        if (typeof args.inPageAnchor !== 'undefined') {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        }

        // reattach Drupal.behaviors to non-inline content
        if (!args.inline) {
          Drupal.attachBehaviors($('#cboxLoadedContent'));
        }
      });

      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each( function() {
        var $triggerElement = $(this);
        var args = {
            cssStyle: {},
            onClosed: function() {
              $triggerElement.focus();
            }
          }, // args sent to overlay
          linkClassNames = $(this).attr('class'), // class name sent to colorbox
          linkHref = $(this).attr('href'), // actual href
          linkHrefWithEmbed = linkHref,
          inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
          overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
          try {
            if( !linkHref.match(/[\&\?]embed=1($|&)/)) {
              linkHrefWithEmbed = ((urlParts.pathname.charAt(0) == "/") ? urlParts.pathname : "/" + urlParts.pathname) + (urlParts.search === "" ? "?" : urlParts.search+"&") + "embed=1" + urlParts.hash;

              // Retain original link if it included the protocol.
              if(linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + "//" + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch(e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
        });

      }); // .each()

    }, // initLinks

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb("language");
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }
  };

  ($)(function(){
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });

})(jQuery);


var site = site || {};
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};
// eslint-disable-next-line no-use-before-define
window.pwr = window.pwr || function () {
  (pwr.q = pwr.q || []).push(arguments);
};

(function($) {
  var pr_products = [];
  var drupalPRSettings = Drupal.settings.analytics ? Drupal.settings.analytics.power_reviews_enh_enabled : false;

  // AV CA - Motion point FR integration.
  var prLocale = '';
  var prMerchantId = '';

  // MTA-75 Analytics enhancements for read/write reviews Ends
  function getSearchResultProduct(resultData, prodId) {
    var prodData = $.grep(resultData, function (data) {
      return data.PRODUCT_ID === prodId;
    });

    return prodData[0];
  }

  // Set up PowerReviews data on product.init
  $(document).on('product.init', '.js-product', function (event, resultData) {
    var $product = $(this);
    var $container = $product.parent();
    if (pwr && typeof Drupal.settings.power_reviews != 'undefined' && $(this).data('product-id') !== '' && $(this).data('product-id') != null) {
      var product = typeof resultData !== 'undefined' ? getSearchResultProduct(resultData, $(this).data('product-id')) : prodcat.data.getProduct($(this).data('product-id'));

      var reviewsnippet_id = 'pr-reviewsnippet-' + (product && product.PROD_BASE_ID ? product.PROD_BASE_ID : '') + '-' + new Date().getTime();
      var $reviewsnippet = $('.review-snippet', this);
      $reviewsnippet.attr('id', reviewsnippet_id);
      var isSppPage = $(this).hasClass('product-full');
      var page_id, review_wrapper_url;
      var prDisclosureCode = Drupal.settings.power_reviews && Drupal.settings.power_reviews.disclosure_code ? Drupal.settings.power_reviews.disclosure_code : '';
      if (product && $reviewsnippet.length && !$reviewsnippet.hasClass('reviews-processed')) {
        $reviewsnippet.addClass('reviews-processed');
        page_id = Drupal.settings.power_reviews.page_id || product.PROD_BASE_ID;
        review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?page_id=' + page_id;

        if (prDisclosureCode) {
          review_wrapper_url += '&pr_disclosure_code=' + prDisclosureCode;
        }

        if (typeof Drupal.settings.power_reviews.merchant_ids != 'undefined') {
          var pr_locale_merchant_id = get_power_reviews_configuration();
          if (typeof pr_locale_merchant_id !== 'undefined' && pr_locale_merchant_id.length > 0) {
            prLocale = document.location.hostname.search('fr.aveda.ca') !== -1 ? Drupal.settings.power_reviews.locale_fr : pr_locale_merchant_id[0]['locale'];
            prMerchantId = document.location.hostname.search('fr.aveda.ca') !== -1 ? Drupal.settings.power_reviews.merchant_id_fr : pr_locale_merchant_id[0]['merchant_id'];
          } else {
            prLocale = document.location.hostname.search('fr.aveda.ca') !== -1 ? Drupal.settings.power_reviews.locale_fr : Drupal.settings.power_reviews.locale;
            prMerchantId = document.location.hostname.search('fr.aveda.ca') !== -1 ? Drupal.settings.power_reviews.merchant_id_fr : Drupal.settings.power_reviews.merchant_id;
          }
        } else {
          prLocale = document.location.hostname.search('fr.aveda.ca') !== -1 ? Drupal.settings.power_reviews.locale_fr : Drupal.settings.power_reviews.locale;
          prMerchantId = document.location.hostname.search('fr.aveda.ca') !== -1 ? Drupal.settings.power_reviews.merchant_id_fr : Drupal.settings.power_reviews.merchant_id;
        }

        var pr_product = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: prLocale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: prMerchantId,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          on_render: function(config, data) {
            var reviewCount = data.review_count || 0;
            var $totalReviewCount = $product.find('.pr-snippet-review-count');
            if ($totalReviewCount.length > 0) {
              $totalReviewCount.html('(' + reviewCount + ')');
            }
          },
          components: {
          }
        };
        if (isSppPage) {
          pr_product.on_read_reviews_click = function() {
            $('.js-pr-rating-tab', $container).click();
            $('html, body').animate({
              'scrollTop': $('#pr-reviewdisplay').offset().top - 175
            }, 1000);
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewRead();
            }
          };
          pr_product.on_write_review_click = function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewWriteStart();
            }
            const war_link = $('.pr-snippet-write-review-link', $product).attr('href');
            if (typeof war_link !== 'undefined' && war_link !== '') {
              window.location.href = war_link;
            }
          };
          pr_product.components.ReviewDisplay = 'pr-reviewdisplay';
          pr_product.components.ReviewSnippet = reviewsnippet_id;
          pr_product.on_render = function(config) {
            if (isSppPage) {
              $('.pr-snippet-stars-container', $product).once().on('click', function () {
                pr_product.on_read_reviews_click();
              });
            }
            if (config.component === 'ReviewDisplay') {
              if ($('.js-spp-reviews-mobile', $container).length === 0) {
                $('.pr-review-snapshot-block-container .pr-review-snapshot-block-cons', $container).append($('.pr-review-snapshot-snippets', $container));
              }
              if(Drupal.settings.power_reviews.filter_lists) {
                $('.pr-rd-main-header-with-filters .pr-multiselect', $container).addClass('disabled');
                Drupal.settings.power_reviews.filter_lists.forEach(function(filters) {
                  $('.pr-rd-main-header-with-filters .pr-multiselect-button-' + filters, $container).removeClass('disabled');
                  $('.pr-rd-main-header-with-filters .pr-multiselect-button-' + filters, $container).addClass('active');
                });
              } else {
                $('.pr-rd-main-header-with-filters .pr-multiselect', $container).addClass('active');
              }

              // Updating the Power Review content copies per every locale's need.
              var reviewers_recommend = site.translations.product.reviewers_recommend || ' OF REVIEWERS RECOMMEND THIS PRODUCT';
              var best_for_tags_title = site.translations.product.best_for_tags_title || 'REVIEWERS FIND THIS PRODUCT BEST FOR';
              var search_button_text = site.translations.product.search_button_text || 'GO';
              var sort_by_text = site.translations.product.sort_by_text || 'Sort By :';
              var filter_by_text = site.translations.product.filter_by_text || 'Filter By :';
              var helpful_text_array = (site.translations.product.helpful_text || 'Helpful (#)').split('#');
              var not_helpful_text_array = (site.translations.product.not_helpful_text || 'Not Helpful (#)').split('#');
              var out_of_5_text = site.translations.product.out_of_5_text || ' out of 5';

              $('.pr-review-snapshot-snippets .pr-reco-value', $container).attr('data-after-content', reviewers_recommend);
              $('.pr-review-snapshot-block-pros .pr-review-snapshot-tags', $container).attr('data-before-content', best_for_tags_title);
              $('.pr-rd-review-header-contents .pr-multiselect.active:first', $container).attr('data-before-content', filter_by_text);
              $('.pr-review-display .pr-rd-main-header-with-filters', $container).attr('data-before-content', filter_by_text);
              $('.pr-rd-review-header-sorts .pr-rd-sort-group', $container).attr('data-before-content', sort_by_text);
              $('.pr-review-snapshot-snippets .pr-snippet-rating-decimal', $container).attr('data-after-content', out_of_5_text);

              var sheet = document.createElement('style');
              sheet.innerHTML = '.pr-helpful-yes .pr-helpful-count:before{content: "' + helpful_text_array[0] + '"}';
              sheet.innerHTML += '.pr-helpful-yes .pr-helpful-count:after{content: "' + helpful_text_array[1] + '"}';
              sheet.innerHTML += '.pr-helpful-no .pr-helpful-count:before{content: "' + not_helpful_text_array[0] + '"}';
              sheet.innerHTML += '.pr-helpful-no .pr-helpful-count:after{content: "' + not_helpful_text_array[1] + '"}';
              sheet.innerHTML += '.pr-rd-search-reviews-icon-button .pr-search-icon:after{content: "' + search_button_text + '"}';
              document.body.appendChild(sheet);

              if (Drupal.settings.power_reviews.display_pros === true && $('.pr-review-snapshot-tags .pr-snapshot-tag-def-filtering', $container).length > 0) {
                $('.pr-review-snapshot-block-container .pr-review-snapshot-block-pros', $container).show();
                if ($('.js-spp-reviews-mobile', $container).length === 0) {
                  $('.pr-review-snapshot-block-container .pr-review-snapshot-block-histogram', $container).css('margin', '0');
                }
              }
            } else if (config.component === 'ReviewSnippet') {
              if ($('.pr-review-snippet-container', $product).hasClass('pr-no-reviews')) {
                $('.pr-qa-snippet-container .pr-ask-question-link', $product).addClass('pr-ask-question-link-first');
                $('#pr-questionsnippet', $product).addClass('pr-ask-link-first-question');
              }
            }
          };
          var image_url = typeof product.IMAGE_LARGE == 'object' ? encodeURI(product.IMAGE_LARGE[0]) : encodeURI(product.IMAGE_LARGE);
          var url = encodeURI(product.url);

          // change hostname from m.* or mtmp.* to www.*
          var mobile_url_pattern = /^(m)(tmp)?(?=\.)/;
          var pr_base_url = document.location.hostname.replace(mobile_url_pattern, 'www');
          var base_url = window.location.protocol + '//' + pr_base_url;

          pr_product.product = {
            name: strip_html_tags(product.PROD_RGN_NAME),
            url: base_url + url,
            image_url: base_url + image_url,
            description: strip_html_tags(product.DESCRIPTION),
            category_name: product.DEFAULT_CAT_ID,
            upc: product.defaultSku.UPC_CODE,
            brand_name: 'Aveda',
            price: product.defaultSku.PRICE,
            in_stock: (!!product.isShoppable).toString()
          };
        } else {
          pr_product.components.CategorySnippet = reviewsnippet_id;
          pr_product.components.ReviewSnippet = reviewsnippet_id;
        }

        pr_products.push(pr_product);
      }
      if (Drupal.settings.power_reviews.show_ask_question && $product.hasClass('product-full') && !$('#pr-questionsnippet', $product).hasClass('question-processed')) {
        $('#pr-questionsnippet', $product).addClass('question-processed');
        $('.js-pr-ask-tab', $container).removeClass('hidden');
        var pr_product_question = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: Drupal.settings.power_reviews.locale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: Drupal.settings.power_reviews.merchant_id,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          on_render: function(config, data) {
            if (config.component === 'QuestionSnippet' && $('.pr-review-snippet-container', $product).hasClass('pr-no-reviews')) {
              $('.pr-qa-snippet-container .pr-ask-question-link', $product).addClass('pr-ask-question-link-first');
            }

            var question_count = data.question_count;
            if (question_count > 0) {
              $('.pr-ask-question-link', $product).addClass('hidden');
              if (!$('.pr-snippet-qa-answer-link', $product).hasClass('count-rendered')) {
                $('.pr-snippet-qa-answer-link', $product).append(' ( ' + question_count + ' ) ');
                $('.pr-snippet-qa-answer-link', $product).addClass('count-rendered');
              }
              if (Drupal.settings.power_reviews.hide_add_answer_button) {
                $('.js-spp-customer-reviews', $container).find('.js-pr-question-display').addClass('pr-qa-hide-answer-link');
              }
            } else {
              $('.pr-snippet-qa-metrics', $product).addClass('hidden');
            }

            // GAPGLOBAL-1256 Analytics enhancements for Ask Answer section Starts
            if (drupalPRSettings) {
              // Tracking while start asking a question
              $('#pr-question-form-link').click(function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() !== 'Cancel') {
                  site.track.questionAsk();
                }
              });

              // Tracking while start adding an answer for a question
              $('.pr-btn-answer').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() !== 'Cancel') {
                  site.track.addAnswerStart();
                }
              });

              // Tracking search action in ask and answer section
              $('.pr-qa-search-btn').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var searchTerm = $(this).siblings('#pr-query').attr('value');
                  if (searchTerm && searchTerm !== '') {
                    var obj = {
                      search_term: searchTerm
                    };
                    site.track.questionAskSearch(obj);
                  }
                }
              });

              // Tracking show more answers click
              $('.pr-qa-display-show-more-questions').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  site.track.showMoreAnswers();
                }
              });
            }
          },

          // Tracking while submitting a question
          on_submit: function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAnswer();
            }
          },
          // GAPGLOBAL-1256 Analytics enhancements for Ask Answer section Ends

          on_ask_question_click: function() {
            $('.js-pr-ask-tab').click();
            var headerHeight = $('.site-header__main').height();
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top - headerHeight
            }, 1000);
            // GAPGLOBAL-1256 Tracking while start reading Q & A section
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAsk();
            }
          },
          on_view_answers_click: function() {
            $('.js-pr-ask-tab').click();
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top
            }, 1000);
            // GAPGLOBAL-1256 Tracking while start reading Q & A section
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAsk();
            }
          },
          components: {
            QuestionSnippet: 'pr-questionsnippet',
            QuestionDisplay: 'pr-questiondisplay',
          }
        };
        pr_products.push(pr_product_question);
      }
    }
  });

  // Set up PowerReviews data on Service page
  $(document).on('product.init-service', '.js-pr-services', function() {

    // AV CA - Motion point FR integration.
    var prLocale = '';
    var prMerchantId = '';

    if (pwr && typeof Drupal.settings.power_reviews != 'undefined' && typeof Drupal.settings.power_reviews.service_page_id != 'undefined') {
      var page_id = Drupal.settings.power_reviews.service_page_id;
      var review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?page_id=' + page_id;
      var prDisclosureCode = Drupal.settings.power_reviews && Drupal.settings.power_reviews.disclosure_code ? Drupal.settings.power_reviews.disclosure_code : '';

      if (prDisclosureCode) {
        review_wrapper_url += '&pr_disclosure_code=' + prDisclosureCode;
      }

      if (typeof Drupal.settings.power_reviews.merchant_ids != 'undefined') {
        var pr_locale_merchant_id = get_power_reviews_configuration();

        prLocale = typeof window.MP != 'undefined' && window.MP.UrlLang === 'fr' ? Drupal.settings.power_reviews.locale_fr : pr_locale_merchant_id[0]['locale'];
        prMerchantId = typeof window.MP != 'undefined' && window.MP.UrlLang === 'fr' ? Drupal.settings.power_reviews.service_merchant_id_fr : pr_locale_merchant_id[0]['merchant_id'];
      } else {
        prLocale = typeof window.MP != 'undefined' && window.MP.UrlLang === 'fr' ? Drupal.settings.power_reviews.locale_fr : Drupal.settings.power_reviews.locale;
        prMerchantId = typeof window.MP != 'undefined' && window.MP.UrlLang === 'fr' ? Drupal.settings.power_reviews.service_merchant_id_fr : Drupal.settings.power_reviews.service_merchant_id;
      }

      var pr_product = {
        api_key: Drupal.settings.power_reviews.service_api_key,
        locale: prLocale,
        merchant_group_id: Drupal.settings.power_reviews.service_merchant_group_id,
        merchant_id: prMerchantId,
        page_id: page_id,
        review_wrapper_url: review_wrapper_url,
        components: {
          ReviewDisplay: 'pr-reviewdisplay'
        },
      };

      pr_products.push(pr_product);
    }
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-start', function() {
    pr_products = [];
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-finish', function() {
    if (pwr && typeof Drupal.settings.power_reviews != 'undefined' && pr_products.length !== 0 && !$('#power_review_container', document).length) {
      pwr('render', pr_products);
    }
  });

  function strip_html_tags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  }

  // Get merchant id and locale for power review based on locale set on the page
  function get_power_reviews_configuration() {
    var pr_configuration = Drupal.settings.power_reviews.merchant_ids;
    var locale = $.cookie('LOCALE') && $.cookie('LOCALE') !== 'en_EU' ? $.cookie('LOCALE').toLowerCase() : 'en_at';
    var pr_locale_merchant_id = new Array();
    if (pr_configuration[locale]) {
      var pr_conf = pr_configuration[locale];
      $.each (pr_conf, function(locale, merchant_id) {
        pr_locale_merchant_id.push({'locale': locale, 'merchant_id': merchant_id});
      });
    }
    return pr_locale_merchant_id;
  }

  $('ul.tabs-nav li').click(function() {
    var tab_id = $(this).attr('data-tab');
    $('.tabs-content').hide();
    $('ul.tabs-nav li').removeClass('tab-active');
    $('.' + tab_id).show().removeClass('hidden');
    $(this).addClass('tab-active');
  });

  // GAPGLOBAL-1256 Tracking while submitting an answer for question
  $(document).on('click', '.pr-answer-form .pr-submit .pr-btn-review', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      site.track.addAnswerEnd();
    }
  });

  // MTA-75 Analytics enhancements for read/write reviews Starts
  // Track sort option in reviews page
  $(document).on('change', '#pr-rd-sort-by', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        sort_option: $('option:selected', this).text()
      };
      site.track.sortReviews(obj);
    }
  });

  // Track thumbs up rating for a review
  $(document).on('click', '.pr-rd-footer .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateReviews(obj);
    }
  });

  // Track thumbs down  rating for a review
  $(document).on('click', '.pr-rd-footer .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateReviews(obj);
    }
  });

  // Track flagging reviews in review page
  $(document).on('click', '.pr-rd-flag-review-container .pr-underline, .pr-flag-review .pr-flag-review-btn-submit', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'flag this review'
      };
      site.track.rateReviews(obj);
    }
  });

  // Track write revew button in reviews section
  $(document).on('click', '.spp_reviews_button', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      site.track.reviewWriteStart();
    }
  });

  // Tracking search action in review section
  $(document).on('click', '.pr-rd-search-reviews-icon-button', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var searchTerm = $(this).siblings(':input').attr('value');
      if (searchTerm && searchTerm !== '') {
        var obj = {
          search_term: searchTerm
        };
        site.track.searchReviews(obj);
      }
    }
  });

  // Tracking filter action in review section
  $(document).on('click', '.pr-multiselect-options li', function() {
    var targetElem = $(this);
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && !(targetElem.attr('data-selected') === 'selected') && drupalPRSettings) {
      targetElem.attr('data-selected', 'selected');
      var selectedFilter = targetElem.closest('.pr-multiselect-options li').filter('[data-selected="selected"]');
      var appliedFiltersArray = [];
      var filter_type_html = targetElem.closest('.pr-multiselect-options').siblings('.pr-multiselect-button').find('span:not(.pr-multiselect-count)');
      var filter_type = '';
      filter_type_html.each(function() {
        var filterText = $(this).text();
        if (!!filterText && filterText.trim() !== '' && filterText.trim() !== '&nbsp;') {
          filter_type = filterText.trim();
        }
      });
      selectedFilter.each(function() {
        appliedFiltersArray.push($(this).text());
      });
      var obj = {
        sort_option: filter_type + ' | ' + appliedFiltersArray.join(', ')
      };
      site.track.filterReviews(obj);
    } else if (targetElem.attr('data-selected') === 'selected') {
      targetElem.attr('data-selected', 'deselected');
    }
  });

  // Tracking thumbs up rating for an answer
  $(document).on('click', '.pr-qa-display-answer .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateAnswers(obj);
    }
  });

  // Tracking thumbs down rating for an answer
  $(document).on('click', '.pr-qa-display-answer .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateAnswers(obj);
    }
  });

  // Tracking sort option in ask and answer section
  $(document).on('change', '#pr-qa-sort-by', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        sort_option: $('option:selected', this).text()
      };
      site.track.sortAnswers(obj);
    }
  });
  $(document).ready(function() {
    var $service = $('.js-pr-services', document);
    if ($service.length > 0) {
      $(document).trigger('products.init-start');
      $service.trigger('product.init-service');
      $(document).trigger('products.init-finish');
    }
  });
})(jQuery);

var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function($) {

prodcat.data.collectProductIds = function($context) {
  var prodIds = [];
  $('[data-product-id]', $context).each(function() {
    var $this = $(this);
    var prodId = $this.attr('data-product-id');
    // Check if a prodId exists
    if (!prodId) {
      return null;
    };
    var insert = true;
    for (var i = prodIds.length - 1; i > -1; i--) {
      if (prodIds[i] === prodId) {
        insert = false;
        break;
      }
    }
    if (insert) {
      prodIds.push(prodId);
    }
    insert = true;
  });
  return prodIds;
};

/**
 * Retrieves product data from data store. Folds full sku data into product.skus array
 * @param {String} prodId
 */
prodcat.data.getProduct = function(prodId) {
  if (!prodcat.data.store.products[prodId]) {
    return null;
  }
  var prodData = $.extend( true, {}, prodcat.data.store.products[prodId] );
  _.each(prodData.skus, function(skuId, idx) {
    prodData.skus[idx] = prodcat.data.getSku(skuId);
  });
  return prodData;
};

prodcat.data.getSku = function(skuId) {
  skuId = skuId + ''; // Has to be a string to run indexOf
  skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
  var skuData = prodcat.data.store.skus[skuId];
  if (!skuData){
    return null;
  }
  // Price fields will be formatted if feature is enabled and a value provided in the global product translations set
  skuData['formattedPrice'] = prodcat.ui.getFormattedPrice(skuData['formattedPrice']);
  skuData['formattedTaxedPrice'] = prodcat.ui.getFormattedPrice(skuData['formattedTaxedPrice']);
  skuData['formattedUnitPrice'] = prodcat.ui.getFormattedPrice(skuData['formattedUnitPrice']);
  return skuData;
};

/**
 * Sanitize the product data to ensure a consistent data structure
 */
prodcat.data.sanitizeProductData = function(productData) {
  // Force the following fields to be an array even if they're originally a string
  var pf = ['LARGE_IMAGE', 'MEDIUM_IMAGE', 'SMALL_IMAGE', 'IMAGE_SMALL', 'IMAGE_MEDIUM', 'IMAGE_LARGE'],
      fk = '';
  // Preprocess shaded fields.
  var product = prodcat.data.prodShadesDetect(productData);

  for (var i = pf.length; i--;) {
    fk = pf[i];
    if (_.isUndefined(product[fk])) {
      continue;
    }
    product[fk] = (typeof product[fk] === 'string') ? [ product[fk] ] : product[fk];
  }
  return product;
};


$(document).on('prodcat.products.update', function(e, productsArray, deepCopy) {
  prodcat.data.updateProducts(productsArray, deepCopy);
});

prodcat.data.updateProducts = function(productsArray, deepCopy) {
  var self = this;
  deepCopy = (deepCopy === false) ? deepCopy : true;  // do a deep copy of the product data by default
  _.each(productsArray, function(newProd) {
    if (!newProd) {
      return;
    }
    var targetProd = $.extend(deepCopy, {}, newProd);
    var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

    if (_.isArray(newProd.skus)) {
      prodcat.data.updateSkus(targetProd.skus);
      targetProd.skus = _.map(targetProd.skus, function(sku) {
        return sku.SKU_ID;
      });
    }

    var prod = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);
    // Find and removes any trailing .00 from the price if enabled
    prod['price_range'] = prodcat.ui.getFormattedPrice(prod['price_range']);
    prod['formattedPriceRange'] = prodcat.ui.getFormattedPrice(prod['formattedPriceRange']);

    // Set defaultSku to the first sku if not already set:
    if (_.isUndefined(prod.defaultSku) && prod.skus && prod.skus.length) {
      prod.defaultSku = self.getSku(prod.skus[0]);
    }

    prodcat.data.store.products[targetProd.PRODUCT_ID] = prod;
  });

  $(document).trigger('prodcat.products.updated', prodcat.data.store.products);
};

/**
 * Sanitize the sku data to ensure a consistent data structure
 */
prodcat.data.sanitizeSkuData = function(skuData) {
  // Force the following fields to be an array even if they're originally a string
  var sf = ['LARGE_IMAGE', 'MEDIUM_IMAGE', 'SMALL_IMAGE', 'IMAGE_SMALL', 'IMAGE_MEDIUM', 'IMAGE_LARGE'],
      fk = '';
  // Preprocess shaded fields.
  var sku = prodcat.data.skuShadesDetect(skuData);

  // Remove any "product" keys from the sku object to prevent recursion errors down the road.
  sku.product = undefined;

  for (var i = sf.length; i--;) {
    fk = sf[i];
    if (_.isUndefined(sku[fk])) {
      continue;
    }
    sku[fk] = (typeof sku[fk] === 'string') ? [ sku[fk] ] : sku[fk];
  }

  return sku;
};

prodcat.data.prodShadesDetect = function (productData) {
  var skuCount;
  var shaded;
  var product = productData;

  if (product['skus']) {
    skuCount = product['skus'].length;
    shaded = product['shaded'];
    if (skuCount > 1 && shaded) {
      product['shaded_sku_count'] = skuCount;
    } else if (skuCount === 1 && shaded) {
      product['single_shade'] = true;
    }
  }

  return product;
};

prodcat.data.skuShadesDetect = function (skuData) {
  var sku = skuData;
  var prodData = prodcat.data.getProduct(sku.PRODUCT_ID);
  var hexValueString;
  var hexes;
  var hexcount;

  if (prodData && prodData['IS_MULTISHADED']) {
    // HEX_VALUE_STRING to new array MultiShadeHexes
    hexValueString = sku['HEX_VALUE_STRING'];
    if (hexValueString) {
      hexes = hexValueString.split(',');
      hexcount = hexes.length;
      sku['MultiShadeHexes'] = hexes;
      sku['MultiShadeCount'] = hexcount;
    }
  }

  return sku;
};

prodcat.data.updateSkus = function(skusArray) {
  _.each(skusArray, function(newSku) {
    newSku = prodcat.data.sanitizeSkuData(newSku);
    var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
    prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
  });
};

prodcat.data.init = function() {
  prodcat.data.store = {
    categories: {},
    products: {},
    skus: {}
  };

  if (typeof page_data === "undefined" || !page_data) {
    return null;
  }

  function _catStore(newCat) {
    var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
    var targetCat = $.extend(true, {}, newCat);
    if (_.isArray(targetCat.products)) {
      prodcat.data.updateProducts(targetCat.products);
    }
    targetCat.products = _.map(targetCat.products, function(prod) {
      return prod.PRODUCT_ID;
    });
    prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
  }

  for (var key in page_data) {
    if (!page_data[key]) {
      continue;
    }
    if (page_data[key].categories && _.isArray(page_data[key].categories)) {
      _.each(page_data[key].categories, _catStore);
    }
    if (page_data[key].products && _.isArray(page_data[key].products)) {
      prodcat.data.updateProducts(page_data[key].products);
    }
    if (page_data[key].product && _.isObject(page_data[key].product)) {
      $(document).trigger('prodcat.products.update', [page_data[key].product]);
      prodcat.data.updateProducts([page_data[key].product]);
    }
  }

  $(document).trigger('prodcat.data.initalized', prodcat.data.store);
};

prodcat.data.pids = prodcat.data.collectProductIds();
// Prevent making an empty call if no prodIds are available.
if (prodcat.data.pids.length > 0) {
  const prodDataNotPreloaded = typeof page_data === 'undefined' || (page_data.hasOwnProperty('data') && Object.keys(page_data.data).length === 0);
  const queryKey = prodDataNotPreloaded ? 'catalog-mpp' : 'catalog-mpp-volatile';

  site.onLoadRpc.requests.push({
    method:   "prodcat.querykey",
    getParams: function() {
      var pids = prodcat.data.pids;
      return [{
        products: pids,
        query_key: queryKey
      }];
    },
    onSuccess: function (r) {
      if (
        !r ||
        !r.result ||
        !r.result.value ||
        !r.result.value.products
      ) {
        return;
      }

      var prods = _.compact(r.result.value.products);
      prodcat.data.updateProducts(prods);

      _.each(prods, function(prod) {
        var prodSlctr = "[data-product-id='" + prod.PRODUCT_ID + "']";
        $(prodSlctr).trigger("inv_status_data:updated");
      });

        if (Drupal.settings.common.show_toos_soldout_message_mpp) {
          $('.mpp-container .js-inv-status-list').each(function() {
            $(this).trigger('product.allSkuValidate');
          });
        }
      if (prods.length > 0) { // can't think of a reason why this WOULD be empty, but check, just in case
        // trigger a custom event, letting all who care know that we've updated inventory status data for every product
        $(document).trigger("inv_status_data:finished");
      }

      $(document).trigger('prodcat.data.query.success', [prodDataNotPreloaded]);
    }
  });
}

prodcat.data.isPaletteMultiSku = function(prodId) {
  var prodData = prodcat.data.getProduct(prodId);
  return !!prodData.isPaletteMultiSku;
};

/**
 * Pull data from page_data and store internally.
 */
Drupal.behaviors.prodcatDataInit = {
  attached: false,
  attach: function() {
    if (!this.attached) {
      prodcat.data.init();
    }
    this.attached = true;
  }
};

})(jQuery);

var site = site || {};
var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function($, generic) {
  'use strict';

  var jsonRpc = generic.jsonrpc;
  var updateCollectionIdEnabledRPCActive = false;
  var updateCollectionIdEnabledQueue = [];
  var getFavoritesActiveRequest = false;

  prodcat.ui.updateCollectionIdEnabled = function (addToCartWrapper = undefined) {
    if (addToCartWrapper) updateCollectionIdEnabledQueue.push(addToCartWrapper);
    if (updateCollectionIdEnabledRPCActive) return;
    updateCollectionIdEnabledRPCActive = jsonRpc.fetch({
      method: 'collection.isEnabled',
      params: [{ COLLECTION_NAME: 'paddle_sticker' }],
      onSuccess: function (jsonRpcResponse) {
        updateCollectionIdEnabledRPCActive = false;
        var value = jsonRpcResponse.getValue();

        prodcat.ui.collectionIdEnabled = value === 1 ? 1 : 0;
        updateCollectionIdEnabledQueue.forEach(function (addToCartWrapper) {
          if (typeof addToCartWrapper === 'object'
            && Object.prototype.hasOwnProperty.call(addToCartWrapper, 'addToCart')) {
            addToCartWrapper.addToCart();
          }
        });
        updateCollectionIdEnabledQueue = [];
      },
      onFailure: function () {
        updateCollectionIdEnabledRPCActive = false;
        prodcat.ui.collectionIdEnabled = 0;
        updateCollectionIdEnabledQueue.forEach(function (addToCartWrapper) {
          if (typeof addToCartWrapper === 'object'
            && Object.prototype.hasOwnProperty.call(addToCartWrapper, 'addToCart')) {
            addToCartWrapper.addToCart();
          }
        });
        updateCollectionIdEnabledQueue = [];
      }
    });
  };

  prodcat.ui.checkCollectionAndAddToCart = function (args, isShipTogether) {
    var addToCartWrapper = {
      args: args,
      isShipTogether: isShipTogether,
      addToCart: function () {
        var newArgs = args;

        if (isShipTogether && prodcat.ui.collectionIdEnabled === 1) {
          newArgs.itemType = 'kitItem';
        }

        if (window.self !== window.top) {
          // Trigger add to bag in parent window
          newArgs['_elc_action'] = 'addtobag';
          newArgs.iframeID = window.parentIFrame.getId();
          newArgs.skuBaseId = newArgs.$addBtn.data('skuBaseId');
          window.parentIFrame.sendMessage(newArgs, '*');
        } else {
          prodcat.ui.addToCart(newArgs);
        }
      }
    };

    if (isShipTogether && prodcat.ui.collectionIdEnabled === undefined) {
      prodcat.ui.updateCollectionIdEnabled(addToCartWrapper);
    } else {
      addToCartWrapper.addToCart();
    }
  };

  prodcat.ui.addKitToCart = function (params) {
    var buySkus = params.skuBaseId || [];
    var args = {};

    args.COLLECTION_SUBTYPE = buySkus.join('_').toString() + '_KIT';
    args.itemType = 'kit';
    args.COLLECTION_TYPE = 'UKIT';
    args.SKU_BASE_ID = buySkus;
    args.INCREMENT = params.INCREMENT || 0;
    args.QTY = parseInt(params.quantity) ? parseInt(params.quantity) : 1;
    args._SUBMIT = 'alter_collection';
    args.HAS_MULTIPLE_SKUS = 1;
    args.COLLECTION_NAME = 'Kit ' + Math.floor((Math.random() * 10000));
    args.action = 'add,edit,create_kit';

    return args;
  };

  /**
   * Adds SKUs to cart.
   */
  prodcat.ui.addToCart = function(args) {
    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }

    var quantity;
    if (typeof args.quantity !== 'undefined') {
      quantity = args.quantity;
    } else {
      quantity = 1;
    }

    var catBaseId = '';
    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        catBaseId = matchResult[0];
      }
    }

    args.skus = args.skus || (_.isString(skuBaseId) ? [skuBaseId] : skuBaseId);
    args.itemType = args.itemType || 'cart';
    args.QTY = quantity || args.QTY;

    // Conditionally add a CAT_BASE_ID key to the list of parameters to send
    var cbid = args.CAT_BASE_ID || catBaseId;
    if (cbid.length > 0) {
      args.CAT_BASE_ID = cbid;
    }

    if (args.itemType === 'kitItem') {
      args = prodcat.ui.addKitToCart(args);
    }

    generic.checkout.cart.updateCart({
      params: args,
      onSuccess: function(r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.success', [resultData]);
        var $gnav_cart = $('.js-gnav-cart, .gnav-util--cart--nonempty');
        $('.js-gnav-util__content__inner--cart').focus();
        site.restrict_navigation($gnav_cart);
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.failure', {
          errorObjects: errorObjectsArray,
          addBtn: args.$addBtn
        });
      }
    });
  };

  /**
 * Adds a SKU to the user's primary favorites list.
 * @param {Object} args
 * @param {Object} args.skuData a set of key-value pairs describing a SKU
 * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
 * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 */
  prodcat.ui.addToFavorites = function(args) {
    var params = {
      _SUBMIT: 'alter_collection',
      action: 'add'
    };

    if (args.action) {
      params.action = args.action;
    }

    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }
    params.SKU_BASE_ID = skuBaseId;

    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        params.CAT_BASE_ID = matchResult[0];
      }
    }

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function(jsonRpcResponse) {
        var d = jsonRpcResponse.getData();
        var r = d.ac_results[0].result;
        var cr;
        if (params.action !== 'delete') {
          if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.exists', [cr, args.$el]);
          } else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.success', [cr, args.$el]);
          }
          $(document).trigger('product.favorites', [args.$el, 'success']);
        } else {
          $(document).trigger('product.favorites', [args.$el, 'removed']);
        }
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
      }
    });
  };

  prodcat.ui.grids = (function() {
    var grids = [];
    return $.extend(prodcat.ui.grids || {}, {
      add: function(grid) {
        if (_.indexOf(grids, grid) === -1) { // if we can't find the grid in our list
          grids.push(grid);
        }
      },
      clean: function(args) {
        if (args.before && typeof args.before === 'function') {
          args.before(grids);
        }
        if (site?.util?.grids) {
          site.util.grids.attach(grids);
        }
        if (args.after && typeof args.before === 'function') {
          args.after(grids);
        }
        grids = [];
      }
    });
  }());

  // Formats the price to remove .00
  prodcat.ui.getFormattedPrice = function(price) {
  // Javascript only performs String.prototype.replace once when passed a string
  // To replace multiple occurences, you must use regular expressions
  // The following Regex finds and removes any .00 if enabled
    if (site.translations && site.translations.product.product_hide_trailing_price_value && !_.isUndefined(price)) {
      var priceRegex = new RegExp('(\\' + site.translations.product.product_hide_trailing_price_value + ')', 'g');
      // console.log(priceRegex);
      return price.replace(priceRegex, '');
    }
    return price;
  };

  prodcat.ui.updateSkuStatus = function (productData, skuBaseId) {
    _.each(productData.skus, function (skuData) {
      skuData.IS_DISPLAYED = skuData.SKU_BASE_ID === skuBaseId;
    });
    prodcat.data.updateProducts(productData);
  };

  // Default product listeners:
  // @TODO comment out all console.log
  $(document).on('product.init', '.js-product', function() {
    var $product = $(this);
    var skuDataL2;
    var prodId = $product.data('product-id') || '';
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(decodeURIComponent(queryString));
    var prodData = prodcat.data.getProduct(prodId);
    var isShaded = prodData?.shaded ?? false;
    var shadeNameAfter = isShaded ? urlParams.get('shade') : urlParams.get('size');
    var shadeNameNoSpaces;

    var skuBaseId = /^\d+$/.test(shadeNameAfter) ? shadeNameAfter : $product.data('sku-base-id');
    // Format our prices before anything else
    $('select.js-sku-menu option, .js-select-box-option', $product).each(function() {
      $(this).html(prodcat.ui.getFormattedPrice($(this).html()));
    });
    try {
      Drupal.behaviors.selectBox.attach(this);
    } catch (e) {
      console.log('Drupal.behaviors.selectBox.attach failed in product.init.');
    }

    // if defaultSku is Sold Out, pick first shoppable sku
    if (!!skuBaseId && !!prodData && !prodData.defaultSku.isShoppable && prodData.skus.length > 1) {
      var validSku = _.findWhere(prodData.skus, {isShoppable: 1});
      skuBaseId = !!validSku ? validSku.SKU_BASE_ID : skuBaseId;
    }

    var changeSppShade = function(shadeName) {
      var productParameter = localStorage.getItem('product_parameter');
      if (!$product.hasClass('product-full') || !prodData) {
        return;
      }

      if (productParameter) {
        shadeNameNoSpaces = decodeURIComponent(productParameter).split('_').join(' ');
        localStorage.removeItem('product_parameter');
      } else {
        shadeNameNoSpaces = decodeURIComponent(encodeURIComponent(shadeName)).split('_').join(' ');
      }

      _.each(prodData.skus, function(sku) {
        if (shadeNameNoSpaces === (sku.SHADENAME || sku.PRODUCT_SIZE)) {
          skuBaseId = sku.SKU_BASE_ID;
        }
      });
    };

    var sortByType = function(productType) {
      prodcat.ui.productType = productType;
      if ($('.product-quickshop').length === 0) {
        $(document).trigger('mpp_sort:product_type', [productType]);
      }
    };

    var routes = {
      '/type/:productType': sortByType
    };

    var router = Router(routes);
    router.init();
    changeSppShade(shadeNameAfter);

    skuDataL2 = prodcat.data.getSku(skuBaseId);
    if (!!skuBaseId && skuDataL2) {
      $(this).trigger('product.skuSelect', [skuBaseId]);
      $(this).trigger('product.initSelects', [skuBaseId]);

    }

    $(this).trigger('product.initFavoritesTooltip', [$product]);

    $(this).trigger('product.updateInvStatus');
  });

$(document).on('grid.reflow', function() {
  var $products = $(this).find('.js-product');
  var $product = $();
  $products.each(function(i, product) {
   $product = $(product);
   $(document).trigger('product.initFavoritesTooltip', [$product]);
  })
});

  $(document).on('product.updateInvStatus', '.js-product', function() {
    var $addBtn = $('.js-add-to-cart, .js-add-to-bag', this);
    var skuBaseId = $addBtn.data('sku-base-id') || $(this).data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);
    var $parentGrid;
    var parentGridOverrideStatus;
    var toutsPlaceholder = [];
    var reorderStatuses = [ // these are the statuses that will trigger a product block to be reordered
      2, // Temporarily Sold Out
      3, // Coming Soon
      7 // Sold Out
    ];

    var reorderTest = function(l2Cache) {
      return _.contains(reorderStatuses, parseInt(l2Cache.INVENTORY_STATUS));
    };

    var reorderSoldOut = function(skuDataL2, el) {
      var $el = $(el);
      var $gridItems = $parentGrid.find('.js-product-grid-item');

      if (!_.isArray(skuDataL2)) {
        skuDataL2 = [ skuDataL2 ];
      }

      if (_.every(skuDataL2, reorderTest)) {
        // Snapshot of initial tout position before reordering
        $gridItems.each(function (i) {
          if ($(this).find('.product-grid__tout').length > 0) {
            toutsPlaceholder.push({
              $item: $(this),
              position: i
            });
          }
        });
        $el.closest('.js-grid-item').appendTo($el.closest('.js-grid-item').parent());
        prodcat.ui.grids.add($el.closest('.js-grid').get(0));
      }

    };

    if (!$addBtn.length) {
      return;
    }

    $parentGrid = $(this).parents('.js-product-grid');
    parentGridOverrideStatus = $parentGrid.data('sold-out-reordering-override');

    // Reordering sold out products (inv. status 7), to be last in display order on MPPs/anywhere they appear in a grid
    if (skuDataL2 && $parentGrid.is('.js-sold-out-reordering') && parentGridOverrideStatus !== 'off' || parentGridOverrideStatus === 'on') {
      reorderSoldOut(prodcat.data.getProduct($(this).data('product-id')).skus, this);

      // Apply original tout positions after reordering products
      toutsPlaceholder.forEach(function (tout) {
        const $gridItems = $parentGrid.find('.js-product-grid-item');
        const $gridItem = $gridItems.eq(tout.position);

        if (!tout.$item.is($gridItem)) {
          tout.$item.insertAfter($gridItem);
        }
      });
    }

    var hasWaitlist = Drupal.settings.common && Drupal.settings.common.has_waitlist;

    if (skuDataL2 && !skuDataL2.isShoppable) {
      $addBtn.addClass('button--disabled').attr('disabled', 'disabled');
      $addBtn.text(site.translations.product.sold_out_stock_msg);

      if (hasWaitlist && skuDataL2.INVENTORY_STATUS - 0 === 7) {
        $(document).trigger('product.waitlist.init', skuDataL2);
      }
    } else {
      if (hasWaitlist && skuDataL2) {
        $(document).trigger('product.waitlist.reset', skuDataL2);
      }

      $addBtn.removeClass('button--disabled').removeAttr('disabled');
    }

  //$(document).trigger('product.updateInvStatusFinished');
  });

  $(document).on('product.updateInvStatusFinished', function() {
    prodcat.ui.grids.clean({
      before: function(grids) {
        _.each(grids, function(grid) {
          var $grid = $(grid),
              hasQuickshop = $grid.hasClass('product-grid--quickshop'),
              // not currently using, but can distinguish on a per grid basis which have inline quickshops
              isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

          if (hasQuickshop && prodcat.ui.quickshop) {
            prodcat.ui.quickshop.reset($grid);
          }
        });
      },
      after: function(grids) {
        _.each(grids, function(grid) {
          var $grid = $(grid),
              hasQuickshop = $grid.hasClass('product-grid--quickshop'),
              // not currently using, but can distinguish on a per grid basis which have inline quickshops
              isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

          if (hasQuickshop && prodcat.ui.quickshop) {
            prodcat.ui.quickshop.init($grid);
          }
        });
      }
    });
  });

  $(document).on('product.quickshopInit', function(e, methods) {
    if (prodcat?.ui?.quickshop) {
      prodcat.ui.quickshop = _.extend(prodcat.ui.quickshop || {}, methods);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
    var $this = $(this);
    var $skuWrapper = $('.js-sku-wrapper', $this);
    var prodData;
    var $faveLink;
    var skuData = prodcat.data.getSku(skuBaseId);
    var $featuredIngredient = $();
    var featuredIngredientCopy = '';
    var $price = $('.js-product-full__price-value', $this);
    var $price2 = $('.js-product-full__price2-value', $this);
    var $size = $('.js-product-full__size-value', $this);
    var $unitPrice = $('.js-product-full__unit-size-value', $this);

    if (!skuData) {
      return;
    }

    prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
    $this.data('sku-base-id', skuBaseId);
    $('.js-add-to-cart, .js-add-to-bag', this).data('sku-base-id', skuBaseId);
    $('.js-add-to-favorites', this).data('sku-base-id', skuBaseId);
    $('.js-inv-status-list', this).data('sku-base-id', skuBaseId);
    if ($price.length > 0) {
      $price.text(skuData.formattedPrice);
      $price2.text(skuData.formattedPrice2);
      $size.text(skuData.PRODUCT_SIZE);

      if (skuData.formattedUnitPrice) {
        $unitPrice.text('(' + skuData.formattedUnitPrice + ')');
      } else {
        $unitPrice.empty();
      }

      if (!skuData.PRICE2) {
        $price.removeClass('has-sale');
        $price2.addClass('hidden');
      } else {
        $price.addClass('has-sale');
        $price2.removeClass('hidden');
      }
    }
    $faveLink = $this.find('.js-add-to-favorites');
    $faveLink.tooltipster(tooltipsterDefaultSettings);

    if ($this.hasClass('js-quickshop')) {
      $this.trigger('product.updateInvStatus');
    }

    var $icon = $this.find('.icon.icon--heart--filled');
    if ($icon.hasClass('icon--heart--filled')) {
      $icon.removeClass('icon--heart--filled').addClass('icon--heart--outline');
    }

    // update select element
    $('select.js-sku-menu', this).val(skuBaseId);

    // update price and size
    var sku = prodcat.data.getSku(skuBaseId);
    var skuBadgeImage = sku.IMAGE_BADGES;
    if (skuBadgeImage) {
      $this.find('.js-product-badge-icon img').attr('data-src', skuBadgeImage).attr('src', skuBadgeImage);
      // To show sku level badge in the absence of prod level badge
      if (!$('.js-product-badge-icon img', this).length) {
        $('<div/>')
          .addClass('product-badge__icon js-product-badge-icon')
          .append($('<img />')
            .addClass('product-badge__image lazyload')
            .attr({
              src: skuBadgeImage,
              loading: 'lazy'
            }))
          .appendTo($('.js-product-badge', this));
      }
    }

    if (sku) {
      prodcat.ui.updateSkuStatus(prodData, skuBaseId);
      if (!!prodData && prodData.KEY_INGREDIENT) {
        $featuredIngredient = $this.find('.js-prd-featured-ingredient');
        featuredIngredientCopy = $('<div />').append($featuredIngredient.clone()).html();
      }
      if (Drupal.settings.common.switch_ingredient_listing && sku.ingredientListing) {
        var ingredientFeatListing = featuredIngredientCopy + sku.ingredientListing;
        $('.js-change-ingredient').html(ingredientFeatListing);
      }
      // Need to wrap sku inside of defaultSku because that's where the template reads
      var data = prodData;
      data.defaultSku = sku;
      var content = site.template.get({
        name: 'product_sku_price',
        data: data
      });
      $('.product-sku-price', this).html($(content).html());
      $('.sku-menu__single', this).text(sku.PRODUCT_SIZE);
      $skuWrapper.toggleClass('has-sale', Boolean(sku.PRICE2));
    }

    $this.trigger('product.skuDisplay', [skuBaseId]);

    // Loyalty point values need to be refreshed
    var $pointsDisplay = $('.js-pure-privilege', this);
    site.loyalty.calculatePoints($pointsDisplay);

    // Reinitialize overlay links
    generic.overlay.initLinks();
  });

  $(document).on('inv_status_data:updated', '.js-product', function() {
    $(this).trigger('product.updateInvStatus');
  });

  // SPP links open in new window if spp_in_new_window=true in config
  if (Drupal.settings.common && Drupal.settings.common.spp_in_new_window) {
    $(document).on('click', '.js-spp-link', function() {
      $(this).attr('target', '_blank');
    });
  }
  // @todo test this
  $(document).on('click', '.js-add-to-cart, .js-add-to-bag', function(e) {
    e.preventDefault();
    var $addBtn = $(this);
    if ($addBtn.is('[disabled]')) {
      return;
    }
    var skuBaseId = $addBtn.data('sku-base-id');
    if (!skuBaseId || skuBaseId.length < 1) {
      return;
    }

    // Trigger the button toggle event to show loading message until all this code and RPC call are complete.
    $(document).trigger('addToCart.toggle', [$addBtn]);

    // Account for adding multiple skus to bag
    var args = {};
    skuBaseId = String(skuBaseId);
    if (skuBaseId.indexOf(',') >= 0) {
    // clean the string
      skuBaseId = skuBaseId.replace(/(\s|\r\n|\n|\r)/gm, '');
      args.skuBaseId = skuBaseId.split(',');
      if (skuBaseId.slice(-1) === ',') {
        args.skuBaseId.pop();
      }
    } else {
      args.skuBaseId = skuBaseId;
    }

    var quantity = $addBtn.data('qty');
    if (!!quantity) {
      args.quantity = quantity;
      args.INCREMENT = quantity;
    } else {
      args.INCREMENT = 1;
    }

    // Replenishment updates when sku is refillable and enable_replenishment=true in config.
    //  (currently applicable only when adding one item at a time)
    if (_.isString(args.skuBaseId) && Drupal.settings.common && Drupal.settings.common.has_replenishment) {
      var skuDataL2 = prodcat.data.getSku(args.skuBaseId);
      if (skuDataL2 !== null && skuDataL2.REFILLABLE) {
        var frequency = $addBtn.attr('data-replenishment');
        if (!!frequency) {
          args.REPLENISHMENT_FREQ = frequency;
        } else {
          args.REPLENISHMENT_FREQ = 0;
        }
        args.action = 'add';
        args.itemType = 'replenishment';
        args.add_to_cart = 1;
      }
    }

    args.LAST_SOURCE = $(location).prop('pathname') || '/';

    args.$addBtn = $addBtn;

    prodcat.ui.checkCollectionAndAddToCart(args, $(e.target).hasClass('js-product-stickers-add-to-bag'));
  });
  $(document).on('click', '.js-spp-link', function () {
    var productURL = $(this).attr('href');
    var urlParams;
    var productParameter;

    if (productURL && productURL.includes('?')) {
      urlParams = new URLSearchParams(productURL.split('?')[1]);
      productParameter = urlParams.get('size') || urlParams.get('shade');

      if (productParameter) {
        localStorage.setItem('product_parameter', productParameter);
      }
    }
  });

  /*
 ***********************
 * Add-to-favorites button
 ***********************
 */
  var tooltipsterDefaultSettings = {
    animation: 'fade',
    arrow: false,
    contentAsHTML: true,
    interactive: true,
    multiple: true,
    onlyOne: true,
    position: 'top',
    restoration: 'none',
    speed: 500,
    theme: 'tooltipster-aveda',
    timer: 3000,
    trigger: 'click',
    updateAnimation: false
  };

  $(document).on('product.initFavoritesTooltip', function(e, $product) {
    var $faveLink = $product.find('.js-add-to-favorites');
    $faveLink.tooltipster(tooltipsterDefaultSettings);
  });

  $(document).on('click', '.js-add-to-favorites', function(e) {
    e.preventDefault();
    var $this = $(this);
    var skuBaseId = $this.data('sku-base-id');
    var $icon = $this.find('.icon');

    $this.tooltipster('disable').tooltipster('hide', function() {
      if ($icon.hasClass('icon--heart--filled')) {
        $this.tooltipster('content', $this.data('tooltip-removed'));
        prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $this, action: 'delete'});
        $this.attr('aria-checked', 'false');
      } else {
        $this.tooltipster('content', $this.data('tooltip-success'));
        prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $this, action: 'add'});
        $this.attr('aria-checked', 'true');
      }
    });
  });

  $(document).on('product.skuDisplay', '.js-product', function (e, skuBaseId) {
    var $productFull = $('.js-product-full-v1', document);
    $(this).trigger('product.updateText', [skuBaseId]);
    $(this).trigger('product.updateShadeRoute', [skuBaseId]);
    $(this).trigger('product.updateLargeImage', [skuBaseId]);
    var skuData = prodcat.data.getSku(skuBaseId);
    var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
    $(this).trigger('product.updateAltImage', [skuBaseId]);

    if ($productFull.length > 0) {
      var $product = $(this);
      $(this).trigger('product.initCarousel', [$product]);
    }
  });


  $(document).on('product.getFavorites', function(e, $favorites) {
    if (getFavoritesActiveRequest) return;
    getFavoritesActiveRequest = generic.jsonrpc.fetch({
      method: 'user.getFavorites',
      onSuccess: function(jsonRpcResponse) {
        getFavoritesActiveRequest = false;
        var rpcResponse = jsonRpcResponse.getValue();
        var skuProducts = rpcResponse.skus;
        var matchResult;
        if (skuProducts.length) {
          _.each(skuProducts, function(sku) {
            if (sku.SKU_ID) {
              matchResult = sku.SKU_ID.match('[0-9]+');
              if (matchResult) {
                $favorites.filter('[data-sku-base-id="' + matchResult[0] + '"]').find('.icon').removeClass('icon--heart--outline').addClass('icon--heart--filled');
              }
            }
          });
        }
      },
      onFailure: function() {
        getFavoritesActiveRequest = false;
      }
    });
  });
  /*
 ****************************
 * SPP Pure Privilege Tooltip
 ****************************
 */

  $(document).on('product.initPurePrivilegeTooltip', function(e, context) {
    var tooltipsterPurePrivilegeSettings = {
      animation: 'fade',
      position: 'top',
      contentAsHTML: true,
      speed: 500,
      theme: 'tooltipster-pure-privilege',
      trigger: 'hover',
      updateAnimation: false
    };
    var container = e.target || context;
    var $skuWrapper = $('.js-sku-wrapper', container);
    var purePrivilegeTooltipText = site.translations.product.spp_pure_privilege_tooltip;

    if ($(container).hasClass('product-full') && typeof purePrivilegeTooltipText !== 'undefined' && purePrivilegeTooltipText !== '' && !site.client.isMobile) {
      $skuWrapper.tooltipster(tooltipsterPurePrivilegeSettings);
      $skuWrapper.once().on('mouseover.purePrivilegeTooltip', function(e) {
        e.preventDefault();
        var $this = $(this);
        $this.tooltipster('content', purePrivilegeTooltipText);
        $this.tooltipster('show');
      });

      $skuWrapper.once().on('mouseleave.purePrivilegeTooltip', function() {
        var $this = $(this);
        $this.tooltipster('hide');
      });
    }
  });

  /*
 ***********************
 * display text fields (price, shade name, description, etc)
 * @todo we are only accounting for one price field - need to make locale friendly
 ***********************
 */
  $(document).on('product.updateText', '.js-product', function(e, skuBaseId) {
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var textFields = [
      { selector: 'js-product-size', field: 'PRODUCT_SIZE' },
      { selector: 'js-product-price', field: 'formattedPrice' },
      {
        selector: 'js-product-price .js-sale-price',
        field: 'formattedPrice'
      },
      {
        selector: 'js-product-price .js-original-price',
        field: 'formattedPrice2'
      },
      { selector: 'js-sku-shade-name', field: 'SHADENAME' },
      { selector: 'js-sku-finish', field: 'FINISH' },
      { selector: 'js-sku-color-group', field: 'ATTRIBUTE_COLOR_FAMILY' },
      { selector: 'js-sku-shade-description', field: 'SHADE_DESCRIPTION' },
      { selector: 'js-misc-flag-text', field: 'MISC_FLAG_TEXT' }
    ];

    if (typeof skuData['PRICE2'] === 'number') {
      $product.find('.js-original-price').addClass('product-brief__price2');
      $product.find('.js-sale-price').addClass('on-sale');
    } else {
      $product.find('.js-original-price').removeClass('product-brief__price2');
      $product.find('.js-sale-price').removeClass('on-sale');
    }

    _.each(textFields, function(el) {
      var $el = $('.' + el.selector, $product);

      if ($el.length < 1
        || (el.selector === 'js-product-price' && $el.hasClass('no-untaxed-price'))) {
        return;
      }

      $el.html(skuData[el.field]);
    });

    // If it's not refillable, hide our refillable options
    // We want the parent of the replenishment dropdown
    var $replenishment = $('.js-replenishment-select', $product).parent();

    $replenishment.toggleClass('hidden', skuData.REFILLABLE !== 1);

    // On SPP/QS, crop description height
    if ($product.hasClass('product-full') || $product.hasClass('product-quickshop')) {
      var $description = $('.product-full__description--modernized', $product);
      var $readMore = $('.product-full__description-more', $description);
      var $readLess = $('.product-full__description-less', $description);
      var descriptionHeight = $description.outerHeight(false);

      if (Drupal.settings.common.pdp_modernized_design) {
        if ($description.length > 0) {
          // Read More Modernized
          if ($description[0].scrollHeight > $description[0].clientHeight + 1) {
            $readMore.removeClass('hidden');
          }
          $('a', $readMore).once().click(function (evt) {
            evt.preventDefault();
            $description[0].classList.add('expanded');
            $readMore.addClass('hidden');
            $readLess.removeClass('hidden');
          });
          $('a', $readLess).once().click(function (evt) {
            evt.preventDefault();
            $description[0].classList.toggle('expanded');
            $readMore.removeClass('hidden');
            $readLess.addClass('hidden');
          });
        }
      } else {
        // Read More Default
        if (descriptionHeight > 100 && !site.client.isMobile) {
          $description.css({
            height: '88px'
          });

          $readMore.removeClass('hidden');

          $('a', $readMore).once().click(function () {
            $readMore.addClass('hidden');
            $description.animate({
              height: descriptionHeight
            }, 150);

            $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');

            return false;
          });
        }
      }
    }

    // Loyalty point values need to be refreshed
    site.loyalty.calculatePoints($('.js-pure-privilege', $product));
  });

  $(document).on('product.favorites', function(e, $el, action) {
    var skuBaseId = $el.attr('data-sku-base-id');
    var $icon = $el.find('.icon').add($el.closest('.js-product-grid').find('.js-add-to-favorites[data-sku-base-id="' + skuBaseId  + '"] .icon'));
    if (action === 'success') {
      $icon.removeClass('icon--heart--outline').addClass('icon--heart--filled');
      $el.tooltipster('enable').tooltipster('show');
    }

    if (action === 'removed') {
      $icon.removeClass('icon--heart--filled').addClass('icon--heart--outline');
      $el.tooltipster('enable').tooltipster('show');
    }
  });

  $(document).on('addToCart.failure addToWishlist.failure', function(event, args) {
  // Escape any html in the alert box.
    var prodAddedMsg = $('<div/>').html(args.errorObjects[0].text).text();
  });

  /**
 * Toggles the visibility of an add-to button, and its sibling loading message
 */
  $(document).on('addToCart.toggle', function(event, $addBtn) {
    if (!$addBtn || $addBtn.length < 1 || !$addBtn.toggleClass) {
      return;
    }

    $addBtn.toggleClass('hidden');

    var loadingDiv = $addBtn.siblings('.js-loading-message').eq(0);

    if (loadingDiv && loadingDiv.length > 0) {
      loadingDiv.toggleClass('hidden');
    }
  });

  $(document).on('product.updateShadeRoute', '.js-product', function(e, skuBaseId) {
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var shadeName = _.result(skuData, 'SHADENAME') || _.result(skuData, 'PRODUCT_SIZE');
    var prodId = skuData?.PRODUCT_ID || $product.data('productId') || '';
    var prodData = prodcat.data.getProduct(prodId);
    var $sppLinks = $('.js-spp-link', $product);
    var isShaded = prodData?.shaded?? '';
    var shadeNameNoSpaces;
    var queryString;
    var urlParams;

    // include reserved characters missing from encodeURIComponent()
    function _encodeComponent(str) {
      return str.replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    }

    if (!!shadeName) {
      shadeNameNoSpaces = _encodeComponent(shadeName.toString().split(' ').join('_'));

      $sppLinks.each(function() {
        if (prodData !== null) {
          $(this).attr('href', prodData.url + (isShaded ? '?shade=' : '?size=') + encodeURIComponent(shadeNameNoSpaces));
        }
      });

      if ($product.hasClass('product-full')) {
        queryString = window.location.search;
        urlParams = new URLSearchParams(queryString);
        if (isShaded) {
          urlParams.set('shade', shadeNameNoSpaces);
        }
        else {
          urlParams.set('size', shadeNameNoSpaces);
        }
        history.replaceState({}, shadeName, '?' + urlParams.toString() + window.location.hash);
      }
    }
  });

  $(document).on('addToCart.failure', function(e, args) {
    var isBopisFailurePopup = args.addBtn && args.addBtn.parent().hasClass('js-bopis-popup-add-to-bag');
    var errorText = '';
    var height = $(window).width() < 767 ? '190px' : '180px';
    $.each(args.errorObjects, function(i, msg) {
      /**
       * Temporary fix to avoid displaying the csrf token error
       * that occurs sporadically when we 1st add a product to cart.
       * More details on NADTX1-2875.
       */
      if (msg.key === 'csrf._token.alter_replenishment') {
        /* eslint no-console: ["error", { allow: ["warn"] }] */
        console.warn('There was a csrf._token.alter_replenishment error, not showing it on pop up.');

        return;
      }
      errorText += '<div class="error-item">' + msg.text + '</div>';
    });
    if (errorText === '') {
      return;
    }
    generic.overlay.launch({
      content: '<div class="product-system-message">' + errorText + '</div>',
      className: isBopisFailurePopup ? 'bopis-failure-popup' : 'colorbox--system-message',
      cssStyle: {
        width: '380px',
        height: isBopisFailurePopup ? height : 'auto'
      },
      onComplete: function () {
        $(window).colorbox.resize({ height: height });
      }
    });
  });

  /**
 * Wire up behavior on page-load according to js- classnames.
 */
  Drupal.behaviors.prodcatUiInit = {
    attach: function (context) {
      var init = function (el) {
        var $product = $('.js-product', el);
        var $favorites = $('.js-add-to-favorites', $product);

        if (prodcat.ui.collectionIdEnabled === undefined) {
          prodcat.ui.updateCollectionIdEnabled();
        }
        $(el).trigger('products.init-start');
        $product.trigger('product.init');
        $(el).trigger('products.init-finish');
        if ($product.hasClass('product-full')) {
          $product.trigger('product.initPurePrivilegeTooltip', el);
        }

        $(document).trigger('product.getFavorites', [$favorites]);
      };

      init(context);
      $(document).on('prodcat.data.query.success', function (e, dataNotPreloaded) {
        if (dataNotPreloaded) {
          init(context);
        }
      });
    }
  };

  // hijack back button instead of going through every shade that was selected
  if (document.referrer.length > 0) {
  // Necessary hack because WebKit fires a popstate event on window load
  // https://code.google.com/p/chromium/issues/detail?id=63040
  // https://bugs.webkit.org/process_bug.cgi
    window.addEventListener('load', function() {
      setTimeout(function() {
        window.addEventListener('popstate', function(e) {
          if (e.state == null) {
            return;
          }
          // send the user back to the referrer when the user has been clicking shades on SPP and wants to go back
          if (e.target.location.hash.match(/^#\/shade/)) {
            window.location = document.referrer;
          }
        });
      }, 0);
    });
  }

  /*
 ***********************
 * swap large image when a sku is selected
 ***********************
 */
  $(document).on('product.updateLargeImage', '.js-product', function(e, skuBaseId) {
    var largeImgField = [
      { selector: 'js-product-large-image', field: 'IMAGE_LARGE' }
    ];
    var skuData = prodcat.data.getSku(skuBaseId);
    var $productEl = $(this);

    _.each(largeImgField, function(el) {
      var $el = $('.' + el.selector, $productEl);
      if ($el.length < 1) {
        return;
      }
      $el.attr('src', skuData[el.field][0]);
    });
  });

  /*
  ***********************
  * sku level carousel
  ***********************
  */
  $(document).on('product.updateAltImage', '.js-product', function(e, skuBaseId) {
    var skuData = prodcat.data.getSku(skuBaseId);
    var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
    var $el = $('.product-full__image--single', this);
    var $hasClassDot = $el.hasClass('has_alt_images_dots');
    var $imageThumbnailsContainer;
    var $cslDot;
    var imageThumbnails;
    var $videoContainer = $('.js-spp-carousel-video-slide', $(this));
    var $imgMedium = $('.js-product__image--med', this);
    // SPP alt video
    if ($videoContainer.length) {
      $videoContainer.once().on('click', function() {
        var opts = {
          openOverlay: true
        };
        if ($(this).data('video-provider') === 'YouTube') {
          opts.provider = 'youtube';
          opts.youTubeId = $(this).data('video-source');
        } else if ($(this).data('video-provider') === 'MP4') {
          var altVideo = document.createElement('video');
          altVideo.src = $(this).data('video-source');
          altVideo.type = 'video/mp4';
          altVideo.controls = true;

          opts.provider = 'html5';
          opts.content = altVideo;
        }
        site.videos.open(opts);
      });
    }
    if ($imgMedium.length && skuData.IMAGE_MEDIUM) {
      if ($imgMedium.hasClass('lazyload') || $imgMedium.hasClass('lazyloaded')) {
        $imgMedium.attr('data-src', skuData.IMAGE_MEDIUM[0]).removeClass('lazyloaded').toggleClass('lazyload', true);
      } else {
        $imgMedium.attr('src', skuData.IMAGE_MEDIUM[0]);
      }
    }
    if (!$el.length) {
      return;
    }
    $el.find('.product-full__image-carousel').remove();
    $imageThumbnailsContainer = $el.find('.js-product-full-image-thumbs');
    if ($imageThumbnailsContainer.length) {
      $imageThumbnailsContainer.remove();
    }
    if (!skuData.LARGE_ALT_IMAGES_WHITE.length) {
      var imgSource = $el.find('.js-product-large-image').attr('src');
      if (!imgSource) {
        var $imgElement = $("<img class='js-product-large-image' />");
        $el.append($imgElement.attr('src', skuData.IMAGE_LARGE[0]));
      }
    }
  });

  /*
  ***********************
  * Spp zoom image
  ***********************
  */
  // NOTE: image zoom was deprecated on spp with the 2020 redesign
  prodcat.ui.imageZoom = {
    _initZoom: function() {
      var clength = $('.product-full__image .product-full__image-carousel').length; //used to check whether the page has carusel or not
      var largeImg = clength ? $('.slick-current.slick-active img.image-zoom', '.js-spp-content') : $('.image-zoom', '.js-spp-content');
      if (largeImg.parents('.js-quickshop').length > 0) {
        return null;
      }
      largeImg.elevateZoom({
        zoomWindowWidth: 450,
        zoomWindowHeight: 600,
        zoomWindowOffetx: 0,
        zoomWindowOffety: 20,
        zoomWindowPosition: 'product-full__image',
        borderSize: 1,
      });
    },
    init: function() {
      if (!Modernizr.touch && !site.client.isMobile) {
        this._initZoom();
      }
    },
  };

  // When the product grid is loaded, update our price and price ranges
  $(document).on('product.gridLoaded', function(e, $grid) {
    $('.js-product-price', $grid).each(function() {
      var self = this;
      var newPrice = prodcat.ui.getFormattedPrice($(self).html());
      $(self).html(newPrice);
    });
  });

  $(window).on('hashchange', function(e) {
    $('.js-product').trigger('product.init');
  });
})(jQuery, window.generic = window.generic || {});

var generic = generic || {};
(function($, FB) {
  var turnRelativeToAbsolute = function(urlToFix) {
    // If the first URL is a backslash, the URL is relative and Pinterest won't pull it. We need to make this a full URL.
    if (urlToFix.substr(0, 1) === '/') {
      // Split the URL by backslashes
      var urlArray = location.href.split('/');
      // Find the web protocol i.e. http, https
      var protocol = urlArray[0];
      // We know there is always 2 backslashes before the website URL
      // Put the protocol and the base url together
      var websiteUrl = protocol + '//' + urlArray[2];
      // Now add the base url to the relative image url to make it absolute
      urlToFix = websiteUrl + urlToFix;
    }
    return urlToFix;
  };

  var productRecordSocialAction = function(platform) {
    var loyaltySocialEarning = Drupal.settings.loyalty_social_earning || '';
    if (Array.isArray(loyaltySocialEarning) && loyaltySocialEarning.length) {
      if (loyaltySocialEarning.includes(platform)) {
        generic.jsonrpc.fetch({
          'method': 'user.recordSocialAction',
          'params': [{ action: 'share', url: window.location.href, platform: platform }],
        });
      }
    }
  };

  $(document).ready(function() {
    // Add media to the pinterest URL
    var $pinterestLink = $('.js-share--pinterest');
    $pinterestLink.each(function() {
      var $ogImage = $('meta[property="og:image"]');
      var mediaURL = false;
      var pinDesc = $(this).closest('.share_sharing_content').find('.share_sharing_media_content').text();
      if ($ogImage.length) {
        mediaURL = $ogImage.attr('content');
      } else {
        // If there's no open graph tag for the time being, find the first product image and use that
        if ($('.js-social-sharing-list').length > 0) {
          mediaURL = $(this).siblings('.js-product-large-image').attr('src');
        } else {
          mediaURL = $('.js-product-large-image').first().attr('src');
        }
      }
      mediaURL = turnRelativeToAbsolute(mediaURL);
      // Append this media to the openURL
      var url = $(this).attr('href') + '&media=' + encodeURI(mediaURL);
      if (pinDesc) {
        url += '&description=' + encodeURI(pinDesc);
      }
      $(this).attr('href', url);
    });
  });

  $(document).on('click', '.js-share', function(event) {
    var platform = '';
    // Email - Modify the URL to strip product name HTML tags before opening
    if ($(this).hasClass('js-share--email')) {
      var subject = $(this).data('subject');
      // Strip all HTML
      subject = $('<div/>').html(subject).text();
      var body = $(this).data('body');
      // Make our mailto: link
      $(this).attr('href', 'mailto:?subject=' + subject + '&body=' + body);
      return;
    }
    // Social Media
    var width = 600;
    var height = 350;
    var shareDesc = $(this).closest('.share_sharing_content').find('.share_sharing_media_content').text();
    var openUrl = $(this).attr('href');
    // Allow for borders.
    var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    // Allow for title and status bars.
    var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeatures = 'status=no,height=' + height + ',width=' + width;
    windowFeatures += ',resizable=yes,left=' + leftPosition + ',top=' + topPosition;
    windowFeatures += ',screenX=' + leftPosition + ',screenY=' + topPosition;
    windowFeatures += ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no';
    // Specifically for the Quickshop on MPP, find and use the media URL for Pinterest sharing
    if ($(this).hasClass('js-share--pinterest')) {
      platform = 'pinterest';
      if ($(this).parents('.js-quickshop-container').length) {
        openUrl += '&media=' + turnRelativeToAbsolute($(this).parents('.js-quickshop-container').find('.js-product-large-image').attr('src'));
      }
    }
    if (shareDesc) {
      openUrl += '&text=' + encodeURI(shareDesc);
    }
    if ($(this).hasClass('js-share--facebook')) {
      var $fbShareUrl = $(this).attr('href');
      var $fbShareHeadLine = $('.share_sharing_title').text();
      var $fbShareMsg = $(this).closest('.share_sharing_content').find('.share_sharing_media_content').text();
      var $imageUrl = $('.js-product-large-image').attr('src');
      $(this).addClass('customize-fb-share');
      $(this).on('click', function(event) {
        if (typeof FB.ui !== 'undefined') {
          event.preventDefault();
          FB.ui({
            method: 'share_open_graph',
            action_type: 'og.shares',
            action_properties: JSON.stringify({
              object: {
                'og:url': $fbShareUrl,
                'og:title': $fbShareHeadLine,
                'og:description': $fbShareMsg,
                'og:image': $imageUrl
              }
            })
          });
        }
      });
      platform = 'facebook';
    }
    window.open(openUrl, 'sharer', windowFeatures);
    if (platform !== '') {
      productRecordSocialAction(platform);
    }
    event.preventDefault();
  });
})(jQuery, window.FB || {});

window.site = window.site || {};

site.template = (function($, _, Mustache) {
  site.templates = site.templates || {};
  site.translations = site.translations || {};

  var defaults = {
    globals: {
      t: site.translations,
      variables: {
        // IE doesn't support location.origin, so...
        site_url: window.location.protocol + '//' + window.location.hostname
      }
    }
  };

  // include config settings from brand common module
  if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.common)) {
    $.extend(defaults.globals.variables, Drupal.settings.common);
  }

  var public = {
    get: function(args) {
      var template = site.templates[args.name];

      // If that didn't work, search for a versioned match of the same template
      // (eg. template_v2)
      if (!template && args.name) {
        for (var key in site.templates) {
          if (site.templates.hasOwnProperty(key)) {
            var matcher = new RegExp(args.name + "_v(\\d+)$");
            if (matcher.test(key)) {
              template = site.templates[key];
              break;
            }
          }
        }
      }

      if (_.isUndefined(template)) {
        console.log('The template ' + args.name + ' cannot be found');
      }

      var rendered = this.render(template, args.data);

      if (_.isFunction(args.callback)) {
        var so = args.callback(rendered);
        if (!_.isUndefined(so)) {
          return so;
        }
      }

      return rendered;
    },

    render: function(template, data) {
      defaults.globals.t = site.translations;
      data = data || {};

      // You can pass just the template as a string if you want:
      if (_.isString(template)) {
        template = {
          content: template,
          data: {}
        };
      }

      var view = $.extend({}, defaults, template.data, data);
      var partials = {};

      if (!_.isUndefined(template.partials)) {
        $.each(template.partials, function(key, name) {
          if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
            console.log('The partial ' + key + ' or ' + name + ' cannot be found');
          }

          var pkey = (!_.isUndefined(site.templates[key])) ? key : name;
          partials[pkey] = site.templates[pkey].content;
        });
      }

      return Mustache.render(template.content, view, partials);
    }
  };

  return public;
})(
  window.jQuery = window.jQuery || function(){},
  window._ = window._ || {},
  window.Mustache = window.Mustache || {}
);

/**
 * Generic ul as select list
 *
 * Usage / example markup:
 *
 *
 *
  */

 (function($){

  /**
   * Basic ul to select look behavior.
   */
  Drupal.behaviors.ulSelectList = {
    attach: function(context) {

      var $list = $('.js-ul-select', context);

      //var $trigger = $block.find('.js-collapsible-block-trigger');

      $list.each(function() {

        var $list_item = $('li', $list);
        var $list_item_first = $('li:first-of-type', $list);
        var $list_item_active = $('li.active', $list);
        $list_item_first.addClass('active');

        $list_item.on('click', function(event) {
          // Remove div#selected if it exists
          if ($('.js-ul-select--selected').length) {
            $('.js-ul-select--selected').remove();
          }
          $list.before('<div class="js-ul-select--selected ul-select--selected">');
          var selected = $('.js-ul-select--selected');
          $list.toggleClass('active');
          // Remove active class from any <li> that has it...
          $list.children().removeClass('active');
          // And add the class to the <li> that gets clicked
          $(this).toggleClass('active');

          var selectedText = $(this).text();
          if ($list.hasClass('active')) {
            selected.text(selectedText).addClass('active');
          }
          else {
            selected.text('').removeClass('active');
          }
        });

        // Close the faux select menu when clicking outside it

        $list.on('clickoutside', function(event) {
          if($list.length) {
            if(!$list.has(event.target).length == 0) {
              return;
            } else {
              $list.removeClass('active');
              $('.js-ul-select--selected').removeClass('active').text('');
            }
          }
        });

      });

    }
  };

})(jQuery);

(function($, generic, site) {

/**
  * Method to grab a cookie and use that to control DOM elements as needed.
  * Handles the setting and getting of the user cookie defined in cookie.name and set in backend.
  * To find where the cookie is set on backend, look to Request::TransactionLocaleHandler.
  * Example cookie structure not signed in:
  *   FE_USER_CART=item_count:1&first_name:&signed_in:0&region_id:0
  * Example cookie structure signed in:
  *   FE_USER_CART=item_count:3&first_name:John&signed_in:1&region_id:0
  * You can set specific functions on page load using events.load or hook into javascript events
  *  by defining them in the events class and adding the event to events.init.
  * The cookie class is used to handle all the cookie functionality such as the setting and getting.
  * This method is meant to be stand alone so should be able to add to a brand without many tweaks.
  * Uses straight javascript so not dependent on a javascript framework except for DOM load.
  * Preferably added to the where ever the globalnav javascript is added within a brand.
  * @memberOf site
*/
site.userInfoCookie = function() {
  // Set global vars here.
  var nodes = {};

  // Private internal cookie class.
  // Leverages generic.cookie to get and set the cookie values.
  var cookie = {
    name: 'FE_USER_CART',
    value: '',
    regEx: function(key) {
      if (!key) {
        return null;
      }
      return new RegExp(key + ':([^;&,}]*)');
    },
    set: function() {
      if (!this.name) {
        return null;
      }
      var userCookie = generic.cookie(this.name);
      this.value = userCookie ? userCookie : '';
    },
    getValue: function(key) {
      var keyVal = this.value.match(this.regEx(key));
      return keyVal ? (keyVal[1] ? keyVal[1] : null) : null;
    },
    setValue: function(key, val) {
      var match  = this.value.match(this.regEx(key));
      var oldValue = match[0];
      var newValue = this.value.replace(match[1], val);
      generic.cookie(this.name, newValue, { path: '/' });
      this.value   = newValue;
    }
  };

  // Private events class that handles all individual events.
  // Add all events in 'init' method so they will get fired on page load.
  // The cart event is commented out but there as an example.
  var events = {
    init: function() {
      this.load();
      //this.cart();
    },
    load: function() {
      _setCartItemsTotal();
    },
    cart: function() {
      /*
      $(document).on('cart:countsUpdated', function() {
        var cartCount = generic.checkout.cart.getTotalItems();
        cookie.setValue('item_count', cartCount);
      });
      */
    }
  };

  /* Additional helper functions below here. */

  // Pulls in the cookie info and updates the DOM;
  var _setCartItemsTotal = function() {
    if (!nodes.cartTotalContainer) {
      return null;
    }

    var valueKey = 'item_count';
    var itemsTotal = cookie.getValue(valueKey) - 0 || 0;
    $.each(nodes.cartTotalContainer, function() {
      $(this).html(itemsTotal || '');
    });
  };

  // BRAND SPECIFIC: Get any DOM nodes and assign them to the global class var nodes. Varies between brands.
  // Helps keep all the brand specific DOM definitions in one spot.
  var _getDomNodes = function() {
    nodes.cartTotalContainer = $('.js-cart_count');
  };

  return {
    init: function() {
      _getDomNodes();
      cookie.set();
      events.init();
    },
    set: function() {
      cookie.set();
    },
    getValue: function(key) {
      return cookie.getValue(key);
    },
    setValue: function(key, val) {
      cookie.setValue(key, val);
    }
  };
}();

// Set the cookie outside of $(document).ready so other scripts can always access it in their $(document).ready:
site.userInfoCookie.set();

})(jQuery, window.generic || {}, window.site || {});

(function($, site) {

site.videos = {
  isMobile: {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
      return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
    }
  },

  open: function(opts) {
    var provider = !_.isEmpty(opts.provider) ? opts.provider : '';

    if (provider == 'youtube') {
      this.openYouTube(opts);
    }
    else if (provider == 'html5') {
      this.openHTML5(opts);
    }
    else {
      console.log('Video provider not yet supported.');
    }
  },

  openYouTube: function(opts) {
    var ytId = opts.youTubeId;
    var context = !_.isEmpty(opts.context) ? opts.context : document;
    var content = '<div class="js-content-block-video-placeholder"></div>';

    // Hide overflow on the body
    $('html,body').addClass('no-scroll');

    generic.overlay.launch({
      content: content,
      transition: 'none',
      fixed: 'true',
      appearDuration: 1.0,
      opacity: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      cssStyle: {
        border: 'none',
        padding: 0,
        backgroundColor: '#000',
        height: '100%',
        width: '100%',
        position: 'fixed',
        className: 'overlay--content-block-video',
        onComplete: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.overlay--content-block-video', context);

          function createVideoPlayerDiv($elem, i) {
            var id = $elem.attr('class') + '-' + i;
            $elem
              .parent()
              .append('<div />')
              .children('div')
              .attr('id', id);
            return id;
          }

          function addVideo(ytId, iframeId) {
            /*global YT */
            new YT.Player(iframeId, {
              height: '100%',
              width: '100%',
              videoId: ytId,
              playerVars: {
                modestbranding: 0,
                controls: 1,
                showinfo: 0,
                rel: 0,
              },
              events: {
                'onReady': function(event) {
                  // Don't autoplay on any mobile device
                  if (!site.videos.isMobile.any()) {
                    event.target.playVideo();
                  }
                },
                'onStateChange': function() {
                  // stuff
                }
              }
            });
          }

          // Create a new div for the player to take over inside of the colorbox
          var iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', _this.$cbox), Math.floor((+new Date()) / 1000));

          // Remove our empty container
          $('.js-content-block-video-placeholder', _this.$cbox).remove();

          // Instantiate the video
          addVideo(ytId, iframeId);

          // Animate in
          _this.$cbox.addClass('active');


          // On any resize, recalculate
          $(window).on('resize.videoOverlay', _.debounce(function() {
            $('html,body').addClass('no-scroll');
            $.colorbox.resize({
              width: '100%',
              height: '100%'
            });
          }, 400));
          $.colorbox.resize({
            width: '100%',
            height: '100%'
          });
        },
        onCleanup: function() {
          var _this = this;
          _this.$cbox.removeClass('active');
          $('html,body').removeClass('no-scroll');
          $(window).off('resize.videoOverlay');
        }
      }
    });
  },

  openHTML5: function(opts) {
    var ytId = opts.youTubeId;
    var content = opts.content;
    var context = !_.isEmpty(opts.context) ? opts.context : document;
    // Hide overflow on the body
    $('html,body').addClass('no-scroll');

    generic.overlay.launch({
      content: content,
      transition: 'none',
      fixed: 'true',
      appearDuration: 1.0,
      opacity: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      cssStyle: {
        border: 'none',
        padding: 0,
        backgroundColor: '#000',
        height: '100%',
        width: '100%',
        position: 'fixed',
        className: 'overlay--content-block-video',
        onComplete: function() {
          var _this = this;
          _this.$cbox = $('#colorbox.overlay--content-block-video', context);

          $('video', _this.$cbox).get(0).play();

          // Animate in
          _this.$cbox.addClass('active');

          // On any resize, recalculate
          $(window).on('resize.videoOverlay', _.debounce(function() {
            $('html,body').addClass('no-scroll');
            $.colorbox.resize({
              width: '100%',
              height: '100%'
            });
          }, 400));
          $.colorbox.resize({
            width: '100%',
            height: '100%'
          });
        },
        onCleanup: function() {
          var _this = this;
          _this.$cbox.removeClass('active');
          $('html,body').removeClass('no-scroll');
          $(window).off('resize.videoOverlay');
        }
      }
    });
  }
};

})(jQuery, site);
